import React, { useState, useEffect } from "react";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import Grid from "@mui/material/Grid";
import { calculateServiceUnpaidBalance } from "utils/common";

function AccountStat({ accounts }) {
  const [paid, setPaid] = useState(0);
  const [balance, setBalance] = useState(0);
  useEffect(() => {
    let balanceAmount = 0;
    let paidAmount = 0;
    accounts.forEach((item) => {
      paidAmount += parseFloat(item.amountPaid ?? 0);
      balanceAmount += item.balance
        ? parseFloat(item.balance)
        : parseFloat(
            calculateServiceUnpaidBalance(
              item.totalAmount,
              item.discount,
              item.amountPaid
            )
          );
    });
    setPaid(paidAmount.toFixed(2));
    setBalance(balanceAmount.toFixed(2));
  }, [accounts]);

  return (
    <Grid container mb={2}>
      <Grid item xs={10} md={5} lg={5} mb={1}>
        <SuiBox p={1} mx={2} mr={1.5} style={styles.statisticTabs}>
          <SuiTypography variant="h8" mr={1} fontWeight="medium">
            Total Amount Paid:
          </SuiTypography>

          <SuiTypography variant="h9" fontWeight="medium">
            {paid}
          </SuiTypography>
        </SuiBox>
      </Grid>
      <Grid item xs={10} lg={3}>
        <SuiBox p={1} mx={0} style={styles.statisticTabs}>
          <SuiTypography variant="h8" mr={1} fontWeight="medium">
            Balance:
          </SuiTypography>

          <SuiTypography
            variant="h9"
            fontWeight="medium"
            style={{ color: "red" }}
          >
            {balance}
          </SuiTypography>
        </SuiBox>
      </Grid>
    </Grid>
  );
}

export default AccountStat;

/**-------------------- styling ------------------ */

const styles = {
  statisticTabs: {
    borderRadius: 8,
    display: "flex",
    background: "#F8F9FA",
  },
};
