import React, { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import ModalRoot from "containers/modal/ModalRoot";
import { Grid, Icon } from "@mui/material";
import SuiInput from "components/SuiInput";
import { globaleDateTimeFormatter } from "utils/helper";
import ModalHeader from "containers/modal-header";
import { updateFirstLetter } from "utils/helper";
import Createable from "containers/Select";
import Table from "examples/Tables/Table";
import DatePicker from "react-datepicker";
import { parse } from "date-fns";
import moment from "moment";
import SuiButton from "components/SuiButton";
import { toast } from "react-toastify";
import { pdf } from "@react-pdf/renderer";
import MyInvoiceDoc from "containers/pdf/billingPdf";
import { blobToBase64 } from "utils/common.js";
import { TailSpin } from "react-loader-spinner";
import { calculateGstSummary } from "utils/common";
import { sendInvoicePdf } from "firebaseConfig";
import { InputOption } from "utils/common";

function Modal({
  open,
  item,
  handleCloseModal,
  isEditable,
  products,
  user,
  downloadAndStorePdf,
}) {
  const [formValues, setFormValues] = useState(item);
  const [productOptions, setProductOptions] = useState([]);
  const [product, setProduct] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const invoiceOptions = [
    {
      value: "mail",
      label: "Send over email",
    },
    {
      value: "download",
      label: "Download invoice",
    },
  ];
  const [dates, setDates] = useState({
    purchasedAt: null,
    startDate: null,
    completionDate: null,
  });
  const [buttonLoader, setButtonLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  useEffect(() => {
    if (!open) {
      setInvoiceOptionError(false);
      setSelectedOption([]);
    }
  }, [open]);
  useEffect(() => {
    if (item?.rowData) {
      setRowData(item?.rowData);
    }
    if (item?.termsAndCondition) {
      setFormValues({
        ...item,
        termsAndCondition: item?.termsAndCondition,
      });
    }
  }, [item?.rowData, item?.termsAndCondition]);

  const parseDate = (dateString) => {
    return parse(dateString, "dd/MM/yyyy h:mm a", new Date());
  };

  useEffect(() => {
    let productArray = products?.map((item, index) => {
      return {
        value: item?.productName,
        label: item?.productName,
        key: index,
      };
    });
    setProductOptions(productArray);
  }, [products]);

  useEffect(() => {
    if (item) {
      setDates({
        purchasedAt: item?.purchasedAt
          ? parseDate(globaleDateTimeFormatter(item?.purchasedAt))
          : null,
      });
      // const initialSelectedProduct = products.find(
      //   (product) => product.id === item.productId
      // );
      let initialRows = [];
      if (!item?.rowData) {
        initialRows = [
          {
            productName: item?.productName,
            quantity: item?.quantity ?? 1,
            rate: item?.mrp,
            amount: (item?.quantity ?? 1) * item?.sellingPriceWOT,
            // gst: initialSelectedProduct?.gst || 0,
            gst: item?.gst || item?.cgst + item?.sgst || item?.igst || 0,
            action: "close",
          },
        ];
        setRowData(initialRows);
      }
      const initialTotalAmount = calculateGstSummary(
        initialRows,
        item?.discount,
        item?.amountPaid
      );

      setFormValues({
        ...item,
        clientId: item?.clientId,
        id: item?.id,
        productName: item?.productName,
        invoiceId: item?.invoiceId,
        invoiceDate: item?.invoiceDate || moment.utc().local().toDate(),
        balance: initialTotalAmount.balance,
        totalAmount: initialTotalAmount.totalAmount,
        totalTaxAmount: initialTotalAmount.totalTaxAmount,
        totalTaxRate: initialTotalAmount.totalTaxRate,
        totalAmountBeforeTax: initialTotalAmount.totalAmountBeforeTax,
        rowData: item?.rowData || initialRows,
        termsAndCondition: item?.termsAndCondition
          ? item?.termsAndCondition
          : user.termsAndConditions,
      });
    }
  }, [item]);

  const columns = [
    {
      name: "Product",
      align: "left",
      minWidth: "150px",
    },
    {
      name: "Quantity",
      align: "left",
      minWidth: "150px",
    },
    {
      name: "Rate",
      align: "left",
      minWidth: "150px",
    },
    {
      name: "Amount",
      align: "left",
      minWidth: "150px",
    },
    {
      name: "GST",
      align: "left",
      minWidth: "150px",
    },
    ...(isEditable
      ? [
          {
            name: "Action",
            align: "left",
            minWidth: "150px",
          },
        ]
      : []),
  ];

  const rows = rowData.map((row, index) => {
    return {
      Product: (
        <SuiInput
          type="text"
          name="productName"
          readOnly={true}
          value={row?.productName}
          onChange={(e) => handleRowInputChange(e, index, "productName")}
        />
      ),
      Quantity: (
        <SuiInput
          type="number"
          name="quantity"
          readOnly={!isEditable}
          value={row?.quantity}
          onChange={(e) => handleRowInputChange(e, index, "quantity")}
        />
      ),
      Rate: (
        <SuiInput
          type="number"
          name="rate"
          readOnly={!isEditable}
          value={row?.rate}
          onChange={(e) => handleRowInputChange(e, index, "rate")}
        />
      ),
      Amount: (
        <SuiInput
          type="text"
          name="amount"
          readOnly={true}
          value={row?.amount}
          onChange={(e) => handleRowInputChange(e, index, "amount")}
        />
      ),
      GST: (
        <SuiInput
          type="number"
          name="gst"
          readOnly={!isEditable}
          value={row?.gst}
          onChange={(e) => handleRowInputChange(e, index, "gst")}
        />
      ),
      Action:
        rowData.length > 1 && isEditable ? (
          <Icon
            sx={{
              cursor: "pointer",
              marginLeft: "18px",
              color: "#344767",
              height: "1em",
              width: "1em",
              fontWeight: "700",
              fontSize: "1rem !important",
            }}
            onClick={() => handleRowDelete(index)}
          >
            close
          </Icon>
        ) : (
          <Icon
            sx={{
              cursor: "pointer",
              marginLeft: "18px",
              color: "#344767",
              height: "1em",
              width: "1em",
              fontWeight: "700",
              fontSize: "1rem !important",
            }}
          >
            close
          </Icon>
        ),
    };
  });

  const handleRowInputChange = (e, rowIndex, fieldName) => {
    const { value } = e.target;
    let updatedValue = value;
    if (fieldName === "quantity" || fieldName === "rate") {
      updatedValue = value > 1 ? value : 1;
    }
    if (fieldName === "gst") {
      updatedValue = value > 0 ? value : 0;
    }
    const updatedRows = [...rowData];
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      [fieldName]: updatedValue,
    };
    if (fieldName === "quantity" || fieldName === "rate") {
      updatedRows[rowIndex].amount =
        updatedRows[rowIndex].quantity * updatedRows[rowIndex].rate;
    }
    setRowData(updatedRows);
    setFormValues((prev) => {
      return { ...prev, rowData: updatedRows };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "discount" || name === "amountPaid") {
      updatedValue = value > 0 ? value : 0;
    }
    if (name === "discount") {
      const discountValue = parseFloat(updatedValue);
      const totalAmount = parseFloat(formValues?.totalAmount);
      const amountPaid = parseFloat(formValues?.amountPaid);
      const balance = totalAmount - amountPaid;
      const roundedDiscountValue = Math.round(discountValue * 100) / 100;
      const roundedTotalAmount = Math.round(totalAmount * 100) / 100;
      const roundedBalance = Math.round(balance * 100) / 100;
      if (
        roundedDiscountValue > roundedTotalAmount ||
        roundedDiscountValue > roundedBalance
      ) {
        toast.warning("Amount can't exceed");
        return;
      }
    }
    if (name === "amountPaid") {
      const amountPaid = parseFloat(updatedValue);
      const discountValue = parseFloat(formValues?.discount);
      const totalAmount = parseFloat(formValues?.totalAmount);
      const balance = totalAmount - discountValue;
      const roundedAmountPaid = Math.round(amountPaid * 100) / 100;
      const roundedTotalAmount = Math.round(totalAmount * 100) / 100;
      const roundedBalance = Math.round(balance * 100) / 100;
      if (
        roundedAmountPaid > roundedTotalAmount ||
        roundedAmountPaid > roundedBalance
      ) {
        toast.warning("Amount can't exceed");
        return;
      }
    }
    setFormValues((prev) => ({
      ...prev,
      [name]: updatedValue,
    }));
  };

  useEffect(() => {
    const newTotalAmount = calculateGstSummary(
      rowData,
      formValues?.discount,
      formValues?.amountPaid
    );
    setFormValues((prevValues) => ({
      ...prevValues,
      balance: newTotalAmount.balance,
      totalTaxAmount: newTotalAmount.totalTaxAmount,
      totalTaxRate: newTotalAmount.totalTaxRate,
      totalAmountBeforeTax: newTotalAmount.totalAmountBeforeTax,
      totalAmount: newTotalAmount.totalAmount,
    }));
  }, [formValues?.discount, formValues?.amountPaid, rowData]);

  const handleRowDelete = (rowIndex) => {
    const updatedRows = rowData.filter((_, index) => index !== rowIndex);
    setRowData(updatedRows);
  };

  const handleProductSelect = (selectedService) => {
    const selectedProductData = products.find(
      (product) => product.productName === selectedService.value
    );
    const rate = selectedProductData?.mrp;
    const quantity = 1;
    const amount = rate * quantity;

    const newRow = {
      productName: selectedProductData?.productName,
      quantity: quantity,
      rate: rate,
      amount: amount,
      gst: selectedProductData?.gst || 0,
      action: "close",
    };
    setRowData((prevRows) => {
      const updatedRows = [...prevRows, newRow];

      const newTotalAmount = calculateGstSummary(
        updatedRows,
        formValues?.discount,
        formValues?.amountPaid
      );

      setFormValues((prevValues) => ({
        ...prevValues,
        balance: newTotalAmount.balance,
        totalTaxAmount: newTotalAmount.totalTaxAmount,
        totalTaxRate: newTotalAmount.totalTaxRate,
        totalAmountBeforeTax: newTotalAmount.totalAmountBeforeTax,
        totalAmount: newTotalAmount.totalAmount,
        rowData: updatedRows,
      }));

      return updatedRows;
    });
    setProduct(null);
  };

  const selectedValues = selectedOption.map((option) => option.value);
  const [invoiceOptionError, setInvoiceOptionError] = useState(false);
  const createInvoiceHandler = async () => {
    setButtonLoader(true);
    if (!selectedValues || selectedValues.length === 0) {
      toast.warning("Please select an invoice option!");
      setInvoiceOptionError(true);
      setButtonLoader(false);
      return;
    }
    try {
      let numberRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let val = formValues?.email;
      let match_ = val.match(numberRegex);
      if (!match_ && val) {
        toast.error(`Please enter valid email!`);
        setButtonLoader(false);
        return;
      }
      if (
        formValues?.email === null ||
        formValues?.email === undefined ||
        formValues?.email === ""
      ) {
        toast.info(`Please enter email!`);
        setButtonLoader(false);
        return;
      }

      setErrorMessage({
        ...errorMessage,
        email: false,
      });

      setFormValues({ ...formValues });

      const blob = await pdf(
        <MyInvoiceDoc
          user={user}
          bill={{ ...formValues }}
          note={user.termsAndConditions}
          type={formValues.type}
        />
      ).toBlob();

      const invoicePdfBase64 = await blobToBase64(blob);

      if (
        selectedValues.includes("mail") &&
        selectedValues.includes("download")
      ) {
        await sendInvoicePdf({
          clientName: formValues.name,
          clientEmail: formValues.email,
          invoicePdfBlob: invoicePdfBase64,
          logo: user.logo,
          name: user.name,
        });
        await downloadAndStorePdf({ bill: { ...formValues }, user });
        toast.info("Invoice sent & created successfully");
        setButtonLoader(false);
      } else if (selectedValues.includes("mail")) {
        await sendInvoicePdf({
          clientName: formValues.name,
          clientEmail: formValues.email,
          invoicePdfBlob: invoicePdfBase64,
          logo: user.logo,
          name: user.name,
        });
        toast.info("Invoice sent successfully");
        setButtonLoader(false);
      } else if (selectedValues.includes("download")) {
        await downloadAndStorePdf({ bill: { ...formValues }, user });
        toast.info("Invoice created successfully");
        setButtonLoader(false);
      }
      // setFormValues(formdata);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong, retry!");
      setButtonLoader(false);
    }
  };

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="80%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <ModalHeader
          mode={formValues?.invoiceId ? "edit" : "create"}
          title="Product billing details"
          subtitle={`Product billing details for ${updateFirstLetter(
            item?.name
          )}`}
        />
        <Icon
          sx={({
            typography: { size, fontWeightBold },
            palette: { dark },
          }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={handleCloseModal}
        >
          close
        </Icon>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Client Name
              </SuiTypography>
              <SuiInput
                type="text"
                // readOnly={true}
                disabled
                value={item?.name ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Customer ID
              </SuiTypography>
              <SuiInput
                type="text"
                // readOnly={true}
                disabled
                value={item?.customerId ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Email
              </SuiTypography>
              <SuiInput
                type="text"
                name="email"
                readOnly={!isEditable}
                value={formValues?.email ?? ""}
                onChange={handleInputChange}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Phone Number
              </SuiTypography>
              <SuiInput
                type="text"
                readOnly={!isEditable}
                value={item?.phoneNumber}
              />
            </SuiBox>
          </Grid>
          {isEditable && (
            <Grid item xs={12} lg={3}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Choose Product
                </SuiTypography>
                <Createable
                  placeholder="Add More Products"
                  options={productOptions}
                  value={product}
                  onChange={(e) => {
                    setProduct(e);
                    handleProductSelect(e);
                  }}
                />
              </SuiBox>
            </Grid>
          )}
          <Grid item xs={12} lg={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Selling Date/Time
              </SuiTypography>
              <SuiInput
                type="text"
                // readOnly={true}
                disabled
                value={globaleDateTimeFormatter(item?.purchasedAt) ?? ""}
              />
            </SuiBox>
          </Grid>
          {isEditable && (
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Invoice Options.
                </SuiTypography>
                <Createable
                  components={{
                    Option: InputOption,
                  }}
                  value={selectedOption}
                  options={invoiceOptions ?? []}
                  style={{
                    borderRadius: "0.5rem",
                    fontSize: 14,
                    zIndex: 999,
                    borderColor: invoiceOptionError ? "red" : "#d2d6da",
                  }}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Invoice Options"
                  onChange={(selectedOption) => {
                    setInvoiceOptionError(false);
                    if (Array.isArray(selectedOption)) {
                      setSelectedOption(selectedOption);
                    }
                  }}
                />
              </SuiBox>
            </Grid>
          )}

          <Grid item xs={12} lg={12}>
            <SuiBox
              sx={{
                width: "100%",
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    width: "100%",
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Divider />
              {rows?.length && (
                <Table columns={columns} rows={rows} boxShadow={false} />
              )}
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={2}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Discount
              </SuiTypography>
              <SuiInput
                type="number"
                name="discount"
                readOnly={!isEditable}
                value={formValues?.discount}
                onChange={handleInputChange}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={2}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Amount Paid
              </SuiTypography>
              <SuiInput
                type="number"
                name="amountPaid"
                readOnly={!isEditable}
                value={formValues?.amountPaid}
                onChange={handleInputChange}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={2}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Total Amount
              </SuiTypography>
              <SuiInput
                type="text"
                readOnly={true}
                value={formValues?.totalAmount}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={3}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Balance
              </SuiTypography>
              <SuiInput
                type="text"
                readOnly={true}
                value={`INR ${formValues?.balance?.toFixed(2)}`}
              />
            </SuiBox>
          </Grid>

          {isEditable && (
            <>
              <Grid item xs={12} lg={3}>
                <SuiBox lineHeight={1}>
                  <SuiTypography mb={1} variant="h6">
                    Invoice Date
                  </SuiTypography>
                  <DatePicker
                    isClearable={isEditable}
                    closeOnScroll={true}
                    selected={formValues?.invoiceDate}
                    style={{ width: `100%` }}
                    onChange={(date) =>
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        invoiceDate: date,
                      }))
                    }
                    className="date-picker-custom-v3"
                    placeholderText="Select Invoice Date"
                    readOnly={!isEditable}
                  />
                </SuiBox>
              </Grid>

              <Grid item xs={12} lg={0}>
                <SuiBox lineHeight={1}>
                  <SuiTypography mb={1} variant="h6">
                    Terms & conditions
                  </SuiTypography>
                  <SuiInput
                    type="text"
                    name="termsAndCondition"
                    value={formValues?.termsAndCondition}
                    readOnly={!isEditable}
                    onChange={handleInputChange}
                    multiline={7}
                  />
                </SuiBox>
              </Grid>
            </>
          )}
        </Grid>

        <SuiBox mt={2} style={{ display: "flex", justifyContent: "center" }}>
          <SuiButton
            color="dark"
            variant="gradient"
            onClick={createInvoiceHandler}
            disabled={
              !formValues?.phoneNumber ||
              !formValues?.email ||
              !formValues?.invoiceDate ||
              buttonLoader
            }
            sx={{
              padding: "15px 0",
              width: "30%",
            }}
          >
            {/* {buttonLoader ? (
              <>
                <div style={{ marginRight: "7px" }}>Create Invoice</div>
                <TailSpin color="#fff" height={24} width={24} />
              </>
            ) : (
              "Create Invoice"
            )} */}
            {buttonLoader ? (
              formValues?.invoiceId ? (
                <>
                  <div style={{ marginRight: "7px" }}>Edit Invoice</div>
                  <TailSpin color="#fff" height={24} width={24} />
                </>
              ) : (
                <>
                  <div style={{ marginRight: "7px" }}>Create Invoice</div>
                  <TailSpin color="#fff" height={24} width={24} />
                </>
              )
            ) : formValues?.invoiceId ? (
              "Edit Invoice"
            ) : (
              "Create Invoice"
            )}
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
