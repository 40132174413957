import React from "react";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import MyDoc from "containers/pdf";
import MyInvoiceDoc from "containers/pdf/billingPdf";
import { getSelectedClientDetails } from "services/clientdetails";
import { toast } from "react-toastify";
import { sendDietPdf } from "firebaseConfig";
import { storeInvoicePdf } from "services/billing";
import { generateFileName } from "./common";
import { storeProductInvoicePdf } from "services/billing";
import { storeConsultationInvoicePdf } from "services/billing";

export const generatePDFDocument = async (
  dietChartSettings,
  chartArray,
  selectedClient,
  profile,
  setLoader,
  mail
) => {
  let chartData = [];
  try {
    setLoader(true);
    let selectedClient_ = await getSelectedClientDetails({
      id: selectedClient.id,
    });
    selectedClient_ = { ...selectedClient_, ...selectedClient };

    for (let i = 0; i < chartArray.length; i++) {
      let dietChart = chartArray[i].dietChart;

      let data = {
        dietChartId: dietChart.dietChartId,
        date: dietChart.date,
        weekPlan: dietChart.weekPlan,
        chartType: dietChart.chartType,
        notes: dietChart.notes,
        table: [],
      };

      let keys = Object.keys(dietChart);

      for (let i = 0; i < keys.length; i++) {
        if (
          keys[i] !== "date" &&
          keys[i] !== "notes" &&
          keys[i] !== "clientId" &&
          keys[i] !== "dietChartId" &&
          keys[i] !== "weekPlan" &&
          keys[i] !== "chartType" &&
          keys[i] !== "createdAt" &&
          keys[i] !== undefined &&
          keys[i] !== "selected"
        ) {
          data = {
            ...data,
            table: [
              ...data["table"],
              {
                timing: keys[i],
                list: dietChart[keys[i]],
              },
            ],
          };
        }
      }

      chartData = [...chartData, data];
    }

    chartData = chartData.slice();
    chartData = chartData.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    const blob = await pdf(
      <MyDoc
        dietchart={chartData}
        profile={profile}
        selectedClient={selectedClient_}
        dietChartSettings={dietChartSettings}
      />
    ).toBlob();

    if (!mail) {
      sendPDF(selectedClient.name, selectedClient.email, blob);
    }

    saveAs(blob, `${selectedClient.name}.pdf`);
    setLoader(false);
  } catch (err) {
    console.log(err);
    toast.error("Something went wrong, retry!!");
    setLoader(false);
  }
};

export const invoiceBillingPdf = async ({
  user,
  bill,
  termsAndConditions,
  type,
}) => {
  try {
    let fileName;
    const blob = await pdf(
      <MyInvoiceDoc
        user={user}
        bill={bill}
        note={termsAndConditions}
        type={type}
      />
    ).toBlob();
    let invoiceId = sessionStorage.getItem("invoiceId");
    if (bill.type === "consultation") {
      fileName = generateFileName(bill.serviceName);
      await storeConsultationInvoicePdf(
        bill.clientId,
        bill.id,
        blob,
        fileName,
        termsAndConditions,
        bill,
        invoiceId
      );
    } else if (!bill.serviceName) {
      fileName = generateFileName(bill.productName);
      await storeProductInvoicePdf(
        bill.clientId,
        bill.id,
        blob,
        fileName,
        termsAndConditions,
        bill,
        invoiceId
      );
    } else {
      fileName = generateFileName(bill.serviceName);
      await storeInvoicePdf(
        bill.clientId,
        bill.id,
        blob,
        fileName,
        termsAndConditions,
        bill,
        invoiceId
      );
    }
    saveAs(blob, fileName);
  } catch (error) {
    throw error;
  }
};

function sendPDF(name, email, pdfBlob) {
  const reader = new FileReader();
  reader.readAsDataURL(pdfBlob);
  reader.onloadend = async () => {
    const base64data = reader.result.split(",")[1];

    // Call the Firebase Cloud Function to send the PDF
    try {
      await sendDietPdf({
        clientName: name,
        clientEmail: email,
        pdfData: base64data,
      });
    } catch (error) {
      console.error("Error sending diet PDF:", error);
    }
  };
}

export const getMinTimestampObject = (item) => {
  if (!item || item === undefined) {
    return null;
  }
  if (item.length === 0) {
    return null;
  }

  const data = item.filter((item) => item?.weight !== "");

  if (data?.length === 0) {
    return null;
  }

  let minTimestampObject = data[0];

  for (let i = 1; i < data.length; i++) {
    let currentTimestamp;

    if (data[i].timestamp.seconds) {
      currentTimestamp = new Date(
        data[i].timestamp.seconds * 1000 +
          data[i].timestamp.nanoseconds / 1000000
      ).getTime();
    } else if (typeof data[i].timestamp === "string") {
      currentTimestamp = new Date(data[i].timestamp).getTime();
    } else {
      throw new Error("Invalid timestamp format");
    }

    let minTimestamp;

    if (minTimestampObject.timestamp.seconds) {
      minTimestamp = new Date(
        minTimestampObject.timestamp.seconds * 1000 +
          minTimestampObject.timestamp.nanoseconds / 1000000
      ).getTime();
    } else if (typeof minTimestampObject.timestamp === "string") {
      minTimestamp = new Date(minTimestampObject.timestamp).getTime();
    } else {
      throw new Error("Invalid timestamp format");
    }

    if (currentTimestamp < minTimestamp) {
      minTimestampObject = data[i];
    }
  }
  return minTimestampObject;
};

export const getMaxTimestampObject = (item) => {
  if (!item || item === undefined) {
    return null;
  }

  if (item.length === 0) {
    return null;
  }

  const data = item.filter((item) => item?.weight !== "");

  if (data?.length === 0) {
    return null;
  }

  let mostRecentTimestampObject = data[0];

  for (let i = 1; i < data.length; i++) {
    let currentTimestamp;

    if (data[i].timestamp.seconds) {
      currentTimestamp = new Date(
        data[i].timestamp.seconds * 1000 +
          data[i].timestamp.nanoseconds / 1000000
      ).getTime();
    } else if (typeof data[i].timestamp === "string") {
      currentTimestamp = new Date(data[i].timestamp).getTime();
    } else {
      throw new Error("Invalid timestamp format");
    }

    let mostRecentTimestamp;

    if (mostRecentTimestampObject.timestamp.seconds) {
      mostRecentTimestamp = new Date(
        mostRecentTimestampObject.timestamp.seconds * 1000 +
          mostRecentTimestampObject.timestamp.nanoseconds / 1000000
      ).getTime();
    } else if (typeof mostRecentTimestampObject.timestamp === "string") {
      mostRecentTimestamp = new Date(
        mostRecentTimestampObject.timestamp
      ).getTime();
    } else {
      throw new Error("Invalid timestamp format");
    }
    if (currentTimestamp > mostRecentTimestamp) {
      mostRecentTimestampObject = data[i];
    }
  }

  return mostRecentTimestampObject;
};

export const extractPDFfilename = (url) => {
  // Split the URL by '/' to get segments
  const segments = url.split("/");
  // Get the last segment
  const lastSegment = segments[segments.length - 1];
  // Decode URI component to handle special characters
  const decodedSegment = decodeURIComponent(lastSegment);
  // Remove the directory path and return the filename
  const querySplit = decodedSegment.split("?")[0];

  const fileName = querySplit?.split("/")[1];

  return fileName;
};
