import SuiBox from "components/SuiBox";
import React, { useState, useEffect, useMemo } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ModalRoot from "containers/modal/ModalRoot";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";

// Soft UI Dashboard React components
import SuiInput from "components/SuiInput";

// Custom styles for the Modal
import { Icon } from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Createable from "containers/Select";
import {
  validateEmail,
  validatePhone,
  calcPriceWT,
  getAge,
} from "utils/common";
import Grid from "@mui/material/Grid";
import SCreateable from "containers/SelectCreateable";
import CustomButton from "containers/CustomButton";
import { UpdateBasicInfoHandler } from "store/features/clients";
import { UpdatePersonalInfoHandler } from "store/features/clients";
import { UpdatePlanInfoHandler } from "store/features/clients";
import { sendUpdateLinkByEmail } from "firebaseConfig";
import { updateFirstLetter } from "utils/helper";
import GST from "components/GST/GST";
import { getDiscountedPrice } from "utils/common";
import { calculateGstBalance } from "utils/common";

const BasicDetail = ({
  formdata,
  setFormdata,
  selectedClient,
  errorMessage,
  formdataCheck,
  onChangeTextName,
  setErrorMessage,
  clientArray,
  intlPhoneNumberArray,
}) => {
  return (
    <SuiBox mt={1}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <SuiBox lineHeight={1}>
            <SuiTypography mb={1} variant="h6">
              Client Name
            </SuiTypography>
            <SCreateable
              options={clientArray ?? []}
              error={errorMessage?.clientName}
              value={selectedClient}
              style={{ borderRadius: "0.5rem", fontSize: 14 }}
              inputStyle={{
                width: 190,
                height: "1.800rem",
                borderRadius: "0.5rem",
              }}
              placeholder="Choose client"
              onChange={(e) => {
                setErrorMessage({
                  ...errorMessage,
                  clientName: false,
                });

                onChangeTextName(e);
              }}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={12} lg={4}>
          <SuiBox lineHeight={1}>
            <SuiTypography mb={1} variant="h6">
              Country Code
            </SuiTypography>
            <SCreateable
              disabled={formdata?.clientId !== null}
              value={formdata?.countryCode}
              options={intlPhoneNumberArray ?? []}
              error={errorMessage?.countryCode}
              style={{ borderRadius: "0.5rem", fontSize: 14 }}
              inputStyle={{
                width: 190,
                height: "1.800rem",
                borderRadius: "0.5rem",
              }}
              onChange={(e) => {
                setErrorMessage({
                  ...errorMessage,
                  countryCode: false,
                });

                setFormdata({ ...formdata, phoneNumber: null, countryCode: e });
              }}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={12} lg={4}>
          <SuiBox lineHeight={1}>
            <SuiTypography mb={1} variant="h6">
              Phone Number
            </SuiTypography>
            <SuiInput
              type="text"
              name="phoneNumber"
              readOnly={formdata?.clientId !== null}
              error={errorMessage?.phoneNumber}
              value={formdata.phoneNumber ?? ""}
              onChange={(e) => {
                let numberRegex = /^\d+$/;
                let val = e.target.value;
                let match_ = val.match(numberRegex);
                if (!match_ && val) {
                  return false;
                }

                if (
                  formdata.countryCode.key === "IN"
                    ? val.length > 10
                    : val.length > formdata?.countryCode?.phoneLength
                ) {
                  toast.info("Invalid phone number");
                  return;
                }

                setErrorMessage({
                  ...errorMessage,
                  [e.target.name]: false,
                });
                setFormdata({
                  ...formdata,
                  [e.target.name]: e.target.value,
                });
              }}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={12} lg={4}>
          <SuiBox lineHeight={1}>
            <SuiTypography mb={1} variant="h6">
              Email
            </SuiTypography>
            <SuiInput
              type="email"
              name="email"
              readOnly={formdataCheck?.clientId !== null}
              error={errorMessage?.email}
              value={formdata.email ?? ""}
              onChange={(e) => {
                if (e.target.value.length > 40) return false;
                setErrorMessage({
                  ...errorMessage,
                  [e.target.name]: false,
                });
                setFormdata({
                  ...formdata,
                  [e.target.name]: e.target.value,
                });
              }}
            />
          </SuiBox>
        </Grid>
      </Grid>
    </SuiBox>
  );
};

const PersonalDetails = ({
  formdata,
  setFormdata,
  errorMessage,
  genderOptions,
  leadOptions,
  familyArray,
  preferenceOptions,
  formdataCheck,
  setErrorMessage,
}) => {
  return (
    <SuiBox mt={1}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <SuiBox lineHeight={1} mt={3}>
            <SuiTypography mb={1} variant="h6">
              Address
            </SuiTypography>
            <SuiInput
              type="text"
              name="address"
              readOnly={
                formdataCheck?.clientId !== null &&
                formdataCheck.address !== null
              }
              multiline
              rows={6}
              error={errorMessage?.address}
              value={formdata.address ?? ""}
              onChange={(e) => {
                setErrorMessage({
                  ...errorMessage,
                  [e.target.name]: false,
                });
                setFormdata({
                  ...formdata,
                  [e.target.name]: e.target.value,
                });
              }}
            />
          </SuiBox>
        </Grid>

        <Grid item xs={12} lg={8}>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Gender
                </SuiTypography>
                <Createable
                  options={genderOptions}
                  disabled={
                    formdataCheck?.clientId !== null &&
                    formdataCheck?.gender?.value !== null
                  }
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      gender: e,
                    });
                  }}
                  value={formdata?.gender}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  DOB
                </SuiTypography>
                <SuiInput
                  type="date"
                  name="dob"
                  readOnly={
                    formdataCheck?.clientId !== null &&
                    formdataCheck.dob !== null
                  }
                  error={errorMessage?.dob}
                  value={formdata.dob ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={4}>
              <SuiBox>
                <SuiTypography mb={1} variant="h6">
                  Occupation
                </SuiTypography>
                <SuiInput
                  type="text"
                  name="occupation"
                  readOnly={
                    formdataCheck?.clientId !== null &&
                    formdataCheck.occupation !== null
                  }
                  error={errorMessage?.occupation}
                  value={formdata.occupation ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={4}>
              <SuiBox>
                <SuiTypography mb={1} variant="h6">
                  Anniversary
                </SuiTypography>
                <SuiInput
                  type="date"
                  name="anniversary"
                  readOnly={
                    formdataCheck?.clientId !== null &&
                    formdataCheck.anniversary !== null
                  }
                  error={errorMessage?.anniversary}
                  value={formdata.anniversary ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
            <Grid item xs={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Age (Yrs)
                </SuiTypography>
                <SuiInput
                  type="number"
                  name="age"
                  readOnly={true}
                  error={errorMessage?.age}
                  value={formdata.age ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={4}>
              <SuiBox lineHeight={1} ml={1}>
                <SuiTypography mb={1} variant="h6">
                  Food preference
                </SuiTypography>
                <Createable
                  options={preferenceOptions}
                  disabled={
                    formdataCheck?.clientId !== null &&
                    formdataCheck.preference?.value !== null
                  }
                  style={{ borderRadius: "0.5rem", fontSize: 14 }}
                  inputStyle={{
                    width: 190,
                    height: "1.850rem",
                    borderRadius: "0.5rem",
                  }}
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      preference: e,
                    });
                  }}
                  value={formdata.preference}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SuiBox lineHeight={1}>
        <Grid container>
          <Grid item xs={4}>
            <SuiBox lineHeight={1} mt={3} mr={2}>
              <SuiTypography mb={1} variant="h6">
                Lead From
              </SuiTypography>
              <Createable
                options={leadOptions}
                disabled={
                  formdataCheck?.clientId !== null &&
                  formdataCheck.leadFrom?.value !== null
                }
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    leadFrom: e,
                  });
                }}
                value={formdata.leadFrom}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={4}>
            <SuiBox lineHeight={1} mt={3} ml={1} mr={2}>
              <SuiTypography mb={1} variant="h6">
                Family Name
              </SuiTypography>
              <SCreateable
                options={familyArray ?? []}
                disabled={
                  formdataCheck?.clientId !== null &&
                  formdataCheck?.familyName.value !== null
                }
                error={errorMessage?.familyName}
                value={formdata?.familyName}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.800rem",
                  borderRadius: "0.5rem",
                }}
                placeholder="Choose/add family"
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    familyName: false,
                  });

                  setFormdata({
                    ...formdata,
                    familyName: e,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={4}>
            <SuiBox lineHeight={1} mt={3} ml={1}>
              <SuiTypography mb={1} variant="h6">
                Referred By
              </SuiTypography>
              <SuiInput
                type="text"
                name="referredBy"
                readOnly={
                  formdataCheck?.clientId !== null &&
                  formdataCheck.referredBy !== null
                }
                error={errorMessage?.referredBy}
                value={formdata.referredBy ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
};

const PlanDetails = ({
  serviceOptions,
  serviceInstanceOptions,
  paymentOptions,
  setFormdata,
  setErrorMessage,
  formdata,
  errorMessage,
  calculateBalance,
  employeeArray,
}) => {
  useEffect(() => {
    const initalAmount = calculateGstBalance(
      formdata?.package?.totalAmount,
      formdata?.clientGst,
      formdata?.discount,
      formdata?.amountPaid
    );
    setFormdata({
      ...formdata,
      balance: initalAmount.balance,
      totalAmountWithTax: initalAmount.totalAmountWithTax,
      clientGst: formdata.sgst + formdata.cgst || formdata.igst || 0,
    });
  }, [formdata]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "discount" || name === "amountPaid") {
      updatedValue = value > 0 ? value : 0;
    }
    if (name === "discount") {
      const discountValue = parseFloat(updatedValue);
      const totalAmount = parseFloat(formdata?.totalAmountWithTax);
      const amountPaid = parseFloat(formdata?.amountPaid);
      const balance = totalAmount - amountPaid;
      const roundedDiscountValue = Math.round(discountValue * 100) / 100;
      const roundedTotalAmount = Math.round(totalAmount * 100) / 100;
      const roundedBalance = Math.round(balance * 100) / 100;
      if (
        roundedDiscountValue > roundedTotalAmount ||
        roundedDiscountValue > roundedBalance
      ) {
        toast("Can't exceed balance amount!");
        return;
      }
    }
    if (name === "amountPaid") {
      const amountPaid = parseFloat(updatedValue);
      const discountValue = parseFloat(formdata?.discount);
      const totalAmount = parseFloat(formdata?.totalAmountWithTax);
      const balance = totalAmount - discountValue;
      const roundedAmountPaid = Math.round(amountPaid * 100) / 100;
      const roundedTotalAmount = Math.round(totalAmount * 100) / 100;
      const roundedBalance = Math.round(balance * 100) / 100;
      if (
        roundedAmountPaid > roundedTotalAmount ||
        roundedAmountPaid > roundedBalance
      ) {
        toast("Can't exceed balance amount!");
        return;
      }
    }
    setFormdata((prev) => ({
      ...prev,
      [name]: updatedValue,
    }));
  };
  useEffect(() => {
    const newBalance = calculateGstBalance(
      formdata?.package?.totalAmount,
      formdata?.clientGst,
      formdata?.discount,
      formdata?.amountPaid
    );
    setFormdata((prevData) => ({
      ...prevData,
      balance: newBalance.balance,
      totalAmountWithTax: newBalance.totalAmountWithTax,
    }));
  }, [
    formdata?.package?.totalAmount,
    formdata?.clientGst,
    formdata?.discount,
    formdata?.amountPaid,
  ]);

  return (
    <>
      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Choose Package
              </SuiTypography>
              <Createable
                options={serviceOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    hsnCode: e.hsnCode,
                    package: e,
                    packageAmount: +e.totalAmount,
                    totalAmount: +e.totalAmount,
                    checkGst: null,
                    gstType: { label: "CGST & SGST", value: "CGST & SGST" },
                    gst: 0,
                    igst: 0,
                    sgst: 0,
                    cgst: 0,
                    gstComment: null,
                    gstNumber: null,
                  });
                }}
                value={formdata.package}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Assign Dietitian
              </SuiTypography>
              <Createable
                value={formdata?.assignDietitian}
                options={employeeArray ?? []}
                style={{
                  borderRadius: "0.5rem",
                  fontSize: 14,
                  zIndex: 999,
                }}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                inputStyle={{
                  width: 190,
                  height: "1.800rem",
                  borderRadius: "0.5rem",
                }}
                placeholder="Choose Employee Access"
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    setFormdata({
                      ...formdata,
                      assignDietitian: options.map((opt) => {
                        return { value: opt.value, label: opt.label };
                      }),
                    });
                  }
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Discount Amount
              </SuiTypography>
              <SuiInput
                type="number"
                name="discount"
                error={errorMessage?.discount}
                value={formdata.discount ?? ""}
                onChange={handleInputChange}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Total Amount
              </SuiTypography>
              <SuiTypography mb={1} variant="h7">
                INR {formdata?.package?.totalAmount ?? "-"}
              </SuiTypography>
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formdata?.checkGst}
                    onChange={() => {
                      setFormdata({
                        ...formdata,
                        checkGst: !formdata?.checkGst,
                        gstTreatment: null,
                        igst: 0,
                        sgst: 0,
                        cgst: 0,
                      });
                    }}
                  />
                }
                label="GST"
                sx={{ marginLeft: 0, width: "fit-content" }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>

      {formdata?.checkGst && (
        <SuiBox mt={3}>
          <GST
            formdata={formdata}
            setFormdata={setFormdata}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            gstTreatment
          />
        </SuiBox>
      )}

      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Amount Paid
              </SuiTypography>
              <SuiInput
                type="number"
                name="amountPaid"
                error={errorMessage?.amountPaid}
                value={formdata.amountPaid ?? ""}
                onChange={handleInputChange}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Payment Mode
              </SuiTypography>
              <Createable
                options={paymentOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    paymentMode: e,
                  });
                }}
                value={formdata.paymentMode}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Balance
              </SuiTypography>
              <SuiTypography mb={1} variant="h7" style={{ color: "red" }}>
                INR {formdata?.balance ? formdata?.balance : calculateBalance()}
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Service Instance
              </SuiTypography>
              <Createable
                options={serviceInstanceOptions}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.850rem",
                  borderRadius: "0.5rem",
                }}
                onChange={(e) => {
                  setFormdata({
                    ...formdata,
                    serviceInstance: e,
                  });
                }}
                value={formdata.serviceInstance}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Start Date
              </SuiTypography>
              <SuiInput
                type="date"
                name="startDate"
                error={errorMessage?.startDate}
                value={formdata.startDate ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Completion Date
              </SuiTypography>
              <SuiInput
                type="date"
                name="completionDate"
                error={errorMessage?.completionDate}
                value={formdata.completionDate ?? ""}
                onChange={(e) => {
                  if (!formdata.startDate) {
                    toast.info("First fill start date");
                    return;
                  }

                  if (new Date(formdata.startDate) > new Date(e.target.value)) {
                    toast.info(
                      "Completion date should be greater than start date"
                    );
                    return;
                  }
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>

      <SuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SuiBox>
              <SuiTypography mb={1} variant="h6">
                Complementary Service?
              </SuiTypography>
              <SuiInput
                type="text"
                name="complementaryService"
                placeholder="Some other service ..."
                rows={6}
                multiline
                error={errorMessage?.complementaryService}
                value={formdata.complementaryService ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SuiBox>
              <SuiTypography mb={1} variant="h6">
                Notes
              </SuiTypography>
              <SuiInput
                type="text"
                name="notes"
                rows={6}
                multiline
                error={errorMessage?.notes}
                value={formdata.notes ?? ""}
                onChange={(e) => {
                  setErrorMessage({
                    ...errorMessage,
                    [e.target.name]: false,
                  });
                  setFormdata({
                    ...formdata,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
};

const steps = ["Basic Details", "Personal Details", "Plan Details"];

export default function AddClientStepper({
  open,
  handleCloseModal,
  services,
  uid,
  clients,
  familyArray,
  name,
  logo,
  employees,
  intlPhoneNumberArray,
}) {
  //for service options list

  const [serviceOptions, setServiceOptions] = useState([]);
  useEffect(() => {
    let serviceOptionsArray = services.map((item) => {
      return {
        label: item.serviceName,
        value: item.id,
        key: item.id,
        totalAmount: calcPriceWT(item.sellingPriceWOT, item.gst),
        hsnCode: item?.hsnCode ? item?.hsnCode : "N/A",
      };
    });
    setServiceOptions(serviceOptionsArray);
  }, [services]);

  const [stepVal, setStepVal] = useState([0, 0, 0]);

  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      clientId: null,
      clientName: null,
      familyName: null,
      phoneNumber: null,
      email: null,
      countryCode: { label: "(+91)     India", value: "+91", key: "IN" },

      address: null,

      gender: null,
      dob: null,
      anniversary: null,
      preference: null,
      occupation: null,

      age: null,
      leadFrom: null,

      referredBy: null,
      package: null,

      discount: 0,
      totalAmount: null,
      packageAmount: null,
      amountPaid: 0,

      serviceInstance: null,
      paymentMode: null,
      startDate: null,
      completionDate: null,

      complementaryService: null,
      notes: null,
      assignDietitian: null,
      checkGst: null,
      gstType: { label: "CGST & SGST", value: "CGST & SGST" },
      gst: 0,
      igst: null,
      sgst: 0,
      cgst: 0,
      gstComment: null,
      gstNumber: null,
    };
  }, []);

  //error handler
  const errorDefault = useMemo(() => {
    return {
      clientName: false,
      familyName: false,
      phoneNumber: false,
      email: false,
      countryCode: false,

      address: false,

      gender: false,
      dob: false,
      anniversary: false,
      occupation: false,

      age: false,
      leadFrom: false,
      preference: false,
      referredBy: false,
      package: false,

      discount: false,
      amountPaid: false,

      serviceInstance: false,
      paymentMode: false,
      startDate: false,
      completionDate: false,

      complementaryService: false,
      notes: false,
      assignDietitian: null,
    };
  }, []);

  const dispatch = useDispatch();

  const [formdata, setFormdata] = useState(defaultHandler);

  const [errorMessage, setErrorMessage] = useState(errorDefault);

  //for submitting data
  const [loader, setLoader] = useState(false);

  const [genderOptions] = useState([
    { value: "Male", label: "Male", key: 1 },
    { value: "Female", label: "Female", key: 2 },
    { value: "Other", label: "Other", key: 3 },
  ]);

  const [preferenceOptions] = useState([
    { value: "Veg", label: "Veg", key: 1 },
    { value: "Non-veg", label: "Non-veg", key: 2 },
    { value: "Eggetarian", label: "Eggetarian", key: 3 },
  ]);

  const [serviceInstanceOptions] = useState([
    { value: "First Time", label: "First Time", key: 1 },
    { value: "Extension", label: "Extension", key: 2 },
    { value: "Returning", label: "Returning", key: 3 },
  ]);

  const [paymentOptions] = useState([
    { value: "Cash", label: "Cash", key: 1 },
    { value: "Online", label: "Online", key: 2 },
  ]);

  const [leadOptions] = useState([
    { value: "Facebook", label: "Facebook", key: 1 },
    { value: "Instagram", label: "Instagram", key: 2 },
    { value: "Twitter", label: "Twitter", key: 3 },
    { value: "Friend", label: "Friend", key: 4 },
    { label: "Walk In", value: "Walk In", key: 5 },
    { label: "Google Ads", value: "Google Ads", key: 6 },
    { label: "BNI", value: "BNI", key: 7 },
    { label: "News Paper", value: "News Paper", key: 8 },
    { label: "FM", value: "FM", key: 9 },
    { label: "Events", value: "Events", key: 10 },
    { label: "Referral", value: "Referral", key: 11 },
    { label: "Whatsapp", value: "Whatsapp", key: 12 },
    { label: "Board", value: "Board", key: 13 },
    { label: "Pamphlet", value: "Pamphlet", key: 14 },
  ]);

  //for searching client details
  const [clientArray, setClientArray] = useState([]);
  const [employeeArray, setEmployeeArray] = useState([]);

  useEffect(() => {
    const clientArray_ = clients.map((item, index) => {
      return {
        key: index,
        step: item.step ?? null,
        value: item.name,
        countryCode: item.countryCode ?? {
          label: "(+91)     India",
          value: "+91",
          key: "IN",
        },
        label:
          item.name +
          `${
            item.countryCode
              ? ` (${item.countryCode.value} ${item.phoneNumber})`
              : ` (+91 ${item.phoneNumber})`
          }`,
        clientId: item?.id,
        clientName: item?.name,
        familyName: item?.familyName,
        phoneNumber: item?.phoneNumber,
        email: item?.email,
        address: item?.address ?? null,
        gender: { value: item?.gender ?? null, label: item?.gender ?? null },
        dob: item?.dob ?? null,
        anniversary: item?.anniversary ?? null,
        occupation: item?.occupation ?? null,
        age: item?.age ?? null,
        preference: {
          value: item?.preference ?? null,
          label: item?.preference ?? null,
        },
        leadFrom: {
          value: item?.leadFrom ?? null,
          label: item?.leadFrom ?? null,
        },
        referredBy: item?.referredBy ?? null,
      };
    });
    setClientArray(clientArray_);
  }, [clients]);

  useEffect(() => {
    const employeeArray_ = employees?.map((item, index) => {
      return {
        key: index,
        value: item.id,
        label: updateFirstLetter(item?.name),
        employeeName: item?.name,
        employeePhoneNumber: item?.phoneNumber,
        employeeEmail: item?.email,
      };
    });

    setEmployeeArray(employeeArray_);
  }, [employees]);

  useEffect(() => {
    if (formdata?.checkGst) {
      setFormdata({
        ...formdata,
        gst:
          formdata.gstType.value === "IGST"
            ? formdata?.igst ?? 0
            : formdata?.sgst + formdata?.cgst ?? 0,
      });
    }
  }, [
    formdata?.gstType?.value,
    formdata.checkGst,
    formdata?.igst,
    formdata?.sgst,
    formdata?.cgst,
    formdata?.gst,
  ]);

  useEffect(() => {
    const discountedPrice = getDiscountedPrice(
      formdata.packageAmount,
      formdata.discount
    );

    let gstAmount = discountedPrice * (formdata.gst / 100);

    if (formdata?.checkGst) {
      setFormdata({
        ...formdata,
        totalAmount: discountedPrice + gstAmount,
      });
    } else {
      setFormdata({ ...formdata, totalAmount: discountedPrice });
    }
  }, [
    formdata?.gst,
    formdata.checkGst,
    formdata?.packageAmount,
    formdata?.discount,
  ]);

  const [formdataCheck, setFormdataCheck] = useState(defaultHandler);
  const [selectedClient, setSelectedClient] = useState();

  const [newClient, setNewClient] = useState(true);

  const onChangeTextName = (e) => {
    setSelectedClient(e);

    if (e.__isNew__) {
      setNewClient(true);
      setFormdata({
        ...formdata,
        step: stepVal,
        clientId: null,
        clientName: e.clientName ?? e.value,
        familyName: { value: null, label: null },
        phoneNumber: null,
        email: null,
        address: null,
        gender: { value: null, label: null },
        dob: null,
        occupation: null,
        anniversary: null,
        age: null,
        preference: { value: null, label: null },
        leadFrom: { value: null, label: null },
        referredBy: null,
        countryCode: e.countryCode ?? {
          label: "(+91)     India",
          value: "+91",
          key: "IN",
        },
      });

      setFormdataCheck({
        ...formdata,
        clientId: null,
        clientName: e.clientName ?? e.value,
        familyName: { value: null, label: null },
        phoneNumber: null,
        email: null,
        address: null,
        gender: { value: null, label: null },
        dob: null,
        anniversary: null,
        occupation: null,
        age: null,
        preference: { value: null, label: null },
        leadFrom: { value: null, label: null },
        referredBy: null,
      });
    } else {
      setNewClient(false);
      setFormdata({
        ...formdata,
        step: e?.step ?? null,
        clientId: e?.clientId,
        clientName: e?.clientName,
        familyName: e?.familyName
          ? { label: e.familyName, value: e.familyName }
          : { value: null, label: null },
        phoneNumber: e?.phoneNumber,
        email: e?.email,
        address: e?.address ?? null,
        gender: e.gender ? e?.gender : null,
        dob: e?.dob ?? null,
        anniversary: e?.anniversary ?? null,
        preference: e?.preference ? e?.preference : null,
        occupation: e?.occupation ?? null,
        age: e?.age ?? null,
        leadFrom: e?.leadFrom ? e.leadFrom : null,
        referredBy: e?.referredBy ?? null,
        countryCode: e?.countryCode ?? {
          label: "(+91)     India",
          value: "+91",
          key: "IN",
        },
      });

      setFormdataCheck({
        ...formdata,
        clientId: e?.id,
        clientName: e?.clientName,
        familyName: e?.familyName
          ? { label: e.familyName, value: e.familyName }
          : { value: null, label: null },
        phoneNumber: e?.phoneNumber,
        email: e?.email,
        address: e?.address ?? null,
        gender: e.gender ? e?.gender : null,
        preference: e?.preference ? e?.preference : null,
        dob: e?.dob ?? null,
        anniversary: e?.anniversary ?? null,
        occupation: e?.occupation ?? null,
        age: e?.age ?? null,
        leadFrom: e?.leadFrom ? e.leadFrom : null,
        referredBy: e?.referredBy ?? null,
      });
    }
  };

  const onChangeCountryCode = (e) => {
    setFormdata({ ...formdata, countryCode: e });
  };

  const calculateBalance = () => {
    if (formdata.totalAmount) {
      return (
        parseFloat(formdata.totalAmount) - parseFloat(formdata.amountPaid)
      ).toFixed(2);
    }
  };

  //for updating date in modal
  useEffect(() => {
    if (formdata.dob) {
      let age = getAge(formdata.dob);
      setFormdata((e) => {
        return {
          ...e,
          age: age,
        };
      });
    }
  }, [formdata.dob]);

  const [activeStep, setActiveStep] = useState(0);

  const renderForm = (step) => {
    switch (step) {
      case 0:
        return (
          <BasicDetail
            formdataCheck={formdataCheck}
            formdata={formdata}
            setFormdata={setFormdata}
            selectedClient={selectedClient}
            errorMessage={errorMessage}
            onChangeTextName={onChangeTextName}
            onChangeCountryCode={onChangeCountryCode}
            setErrorMessage={setErrorMessage}
            clientArray={clientArray}
            intlPhoneNumberArray={intlPhoneNumberArray}
          />
        );
      case 1:
        return (
          <PersonalDetails
            formdataCheck={formdataCheck}
            formdata={formdata}
            setFormdata={setFormdata}
            selectedClient={selectedClient}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            genderOptions={genderOptions}
            leadOptions={leadOptions}
            familyArray={familyArray}
            preferenceOptions={preferenceOptions}
          />
        );
      case 2:
        return (
          <PlanDetails
            serviceOptions={serviceOptions}
            serviceInstanceOptions={serviceInstanceOptions}
            paymentOptions={paymentOptions}
            setFormdata={setFormdata}
            setErrorMessage={setErrorMessage}
            formdata={formdata}
            errorMessage={errorMessage}
            calculateBalance={calculateBalance}
            employeeArray={employeeArray}
          />
        );
      default:
        return null;
    }
  };

  const handleBasicInfo = async () => {
    if (!formdata.clientName) {
      toast.error("Please enter a Client Name");
      setErrorMessage({
        ...errorMessage,
        clientName: true,
      });
      return;
    }

    if (
      formdata.phoneNumber === null ||
      formdata.phoneNumber === undefined ||
      !formdata.phoneNumber.length
    ) {
      toast.info("Please enter phone number");
      return;
    } else if (
      formdata.countryCode.key === "IN"
        ? formdata.phoneNumber.length < 10
        : formdata.phoneNumber.length < formdata.countryCode.phoneLength
    ) {
      toast.info("Invalid phone number");
      return;
    }

    if (formdata.email) {
      if (!validateEmail(formdata.email)) {
        toast.info("Enter a valid Email");
        setErrorMessage({
          ...errorMessage,
          email: true,
        });
        return;
      }
    } else {
      toast.info("Please enter Email");
      setErrorMessage({
        ...errorMessage,
        email: true,
      });
      return;
    }

    if (!newClient) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      return;
    }

    try {
      setLoader(true);
      const data = await dispatch(
        UpdateBasicInfoHandler({ formdata, uid, stepVal })
      );
      if (data?.error) {
        setLoader(false);
      } else {
        setStepVal((e) => {
          e[0] = 1;
          return e;
        });
        setFormdata((e) => {
          e = { ...e, clientId: data?.payload?.id };
          return e;
        });
        setLoader(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong, retry!");
    }
  };

  const handlePersonalInfo = async () => {
    if (!formdata.address) {
      toast.error("Please fill Address field");
      setErrorMessage({
        ...errorMessage,
        address: true,
      });
      return;
    }

    if (!formdata.gender) {
      toast.error("Please fill Gender field");
      setErrorMessage({
        ...errorMessage,
        gender: true,
      });
      return;
    }

    if (!formdata.dob) {
      toast.error("Please fill DOB field");
      setErrorMessage({
        ...errorMessage,
        dob: true,
      });
      return;
    }

    if (formdata.age === null) {
      toast.error("Please fill Age field");
      setErrorMessage({
        ...errorMessage,
        age: true,
      });
      return;
    }

    if (formdata.preference === null) {
      toast.error("Please fill Preference field");
      setErrorMessage({
        ...errorMessage,
        preference: true,
      });
      return;
    }

    if (!formdata.leadFrom) {
      toast.error("Please select Lead From field");
      setErrorMessage({
        ...errorMessage,
        leadFrom: true,
      });
      return;
    }

    if (!formdata.occupation) {
      toast.error("Please fill Occupation field");
      setErrorMessage({
        ...errorMessage,
        occupation: true,
      });
      return;
    }

    if (!formdata.referredBy) {
      toast.error("Please fill Referred By field");
      setErrorMessage({
        ...errorMessage,
        referredBy: true,
      });
      return;
    }

    try {
      let stepValue;
      if (!formdata.step) {
        stepValue = [1, 1, 1];
      } else {
        stepValue = formdata.step;
      }

      setLoader(true);
      await dispatch(
        UpdatePersonalInfoHandler({ formdata, stepVal: stepValue })
      );
      setLoader(false);
      setStepVal((e) => {
        e[1] = 1;
        return e;
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong, retry!");
    }
  };

  const handlePlanInfo = async () => {
    const gstNumberRegex =
      /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    const gstNumberMatch = formdata?.gstNumber?.match(gstNumberRegex);
    if (!formdata.package) {
      toast.error("Please select Choose Package field");
      setErrorMessage({
        ...errorMessage,
        package: true,
      });
      return;
    }

    if (formdata.discount === null || formdata.discount === "") {
      toast.error("Please fill Discount field");
      setErrorMessage({
        ...errorMessage,
        discount: true,
      });
      return;
    }

    if (formdata.discount < 0) {
      toast.info("Discount value can't be negative");
      setErrorMessage({
        ...errorMessage,
        discount: true,
      });
      return;
    }

    if (formdata?.checkGst) {
      if (!formdata.gstTreatment) {
        toast.error("Please fill GST treatment field");
        setErrorMessage({
          ...errorMessage,
          cgst: true,
        });
        return;
      }
      if (
        !formdata?.gstNumber?.length ||
        formdata?.gstNumber === undefined ||
        formdata?.gstNumber === null
      ) {
        return toast.error("Please fill GST number!!");
      } else if (!gstNumberMatch) {
        return toast.error("Invalid GST number!!");
      }

      if (formdata?.gstType.value === "IGST") {
        if (!formdata.igst) {
          toast.error("Please fill IGST field");
          setErrorMessage({
            ...errorMessage,
            igst: true,
          });
          return;
        }
      } else {
        if (!formdata.sgst) {
          toast.error("Please fill SGST field");
          setErrorMessage({
            ...errorMessage,
            sgst: true,
          });
          return;
        }

        if (!formdata.cgst) {
          toast.error("Please fill CGST field");
          setErrorMessage({
            ...errorMessage,
            cgst: true,
          });
          return;
        }
      }
    }

    if (formdata.amountPaid === null || formdata.amountPaid === "") {
      toast.error("Please fill Amount Paid field");
      setErrorMessage({
        ...errorMessage,
        amountPaid: true,
      });
      return;
    }

    if (formdata.amountPaid < 0) {
      toast.info("Amount Paid value can't be negative");
      setErrorMessage({
        ...errorMessage,
        amountPaid: true,
      });
      return;
    }

    if (!formdata.serviceInstance) {
      toast.error("Please select Service Instance field");
      setErrorMessage({
        ...errorMessage,
        serviceInstance: true,
      });
      return;
    }

    if (!formdata.paymentMode) {
      toast.error("Please select Payment Mode field");
      setErrorMessage({
        ...errorMessage,
        paymentMode: true,
      });
      return;
    }

    if (!formdata.startDate) {
      toast.error("Please fill Start Date field");
      setErrorMessage({
        ...errorMessage,
        startDate: true,
      });
      return;
    }

    if (!formdata.completionDate) {
      toast.error("Please fill Completion Date field");
      setErrorMessage({
        ...errorMessage,
        completionDate: true,
      });
      return;
    }

    if (!formdata.complementaryService) {
      toast.error("Please fill Complementary Service field");
      setErrorMessage({
        ...errorMessage,
        complementaryService: true,
      });
      return;
    }

    if (!formdata.notes) {
      toast.error("Please fill Notes field");
      setErrorMessage({
        ...errorMessage,
        notes: true,
      });
      return;
    }

    if (new Date(formdata.startDate) > new Date(formdata.completionDate)) {
      toast.info("Completion Date should be greater than Start Date");
      setErrorMessage({
        ...errorMessage,
        completionDate: true,
      });
      return;
    }

    let stepValue;
    if (!formdata.step) {
      stepValue = [1, 1, 1];
    } else {
      stepValue = formdata.step;
    }

    try {
      setLoader(true);
      const updateLink = getUserLink(formdata.clientId);
      const userEmail = formdata.email;
      await dispatch(UpdatePlanInfoHandler({ formdata, stepVal: stepValue }));
      await sendUpdateLinkByEmail({
        userEmail,
        updateLink,
        name,
        logo,
      });

      setStepVal((e) => {
        e[2] = 1;
        return e;
      });

      handleCloseModal();

      setTimeout(() => {
        setActiveStep(0);
        setSelectedClient(null);
        setLoader(false);
        setFormdata({
          ...defaultHandler,
        });
        setFormdataCheck({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 100);
      setLoader(false);
    } catch (error) {
      toast.error("Something went wrong!");
      setLoader(false);
    }
  };
  const getUserLink = (clientId) => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/customer/${uid}/${clientId}`;
  };

  const skipHandler = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const [skipLoader, setSkipLoader] = useState(false);
  const skipAndSendLink = async () => {
    try {
      setSkipLoader(true);
      const updateLink = getUserLink(formdata.clientId);
      const userEmail = formdata.email;
      await sendUpdateLinkByEmail({
        userEmail,
        updateLink,
        name,
        logo,
      });
      setSkipLoader(false);
      toast.success("Link sent successfully");

      handleCloseModal();
      setTimeout(() => {
        setActiveStep(0);
        setSelectedClient(null);
        setLoader(false);
        setFormdata({
          ...defaultHandler,
        });
        setFormdataCheck({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
      }, 100);
    } catch (error) {
      setSkipLoader(false);
      toast.error("Something went wrong!!");
    }
  };

  const handleStepClick = (index) => {
    if (index < activeStep) {
      setActiveStep(index);
    }
  };

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="75%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SuiBox>
          <SuiTypography variant="h5">Add Client (For Services)</SuiTypography>
          <SuiTypography variant="body2" color="text">
            Provide client details & add
          </SuiTypography>
        </SuiBox>

        <Icon
          sx={({
            typography: { size, fontWeightBold },
            palette: { dark },
          }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={() => {
            handleCloseModal();
            setActiveStep(0);
            setTimeout(() => {
              setSelectedClient(null);
              setFormdata({
                ...defaultHandler,
              });
              setFormdataCheck({
                ...defaultHandler,
              });
              setErrorMessage({
                ...errorDefault,
              });
            }, 500);
          }}
        >
          close
        </Icon>
      </SuiBox>
      <Divider />
      <SuiBox>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step
              key={label}
              onClick={() => handleStepClick(index)}
              sx={{ cursor: "pointer" }}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Divider />
        <SuiBox px={3} mt={4}>
          {renderForm(activeStep)}
          <SuiBox display="flex" justifyContent="center" my={3}>
            {activeStep === 0 && (
              <SuiBox mx={2}>
                <CustomButton
                  color="dark"
                  variant="gradient"
                  onClick={handleBasicInfo}
                  fullWidth
                  width={180}
                  disabled={loader || !open}
                  title={"Save And Next"}
                  loader={loader}
                />
              </SuiBox>
            )}

            {activeStep === 1 && (
              <>
                <SuiBox mr={2}>
                  <SuiButton
                    color="dark"
                    variant="gradient"
                    onClick={skipHandler}
                  >
                    Skip And Next
                  </SuiButton>
                </SuiBox>
                <SuiBox>
                  <CustomButton
                    color="dark"
                    variant="gradient"
                    onClick={handlePersonalInfo}
                    fullWidth
                    width={180}
                    disabled={loader || !open}
                    title={"Save And Next"}
                    loader={loader}
                  />
                </SuiBox>
              </>
            )}
            {activeStep === steps.length - 1 && (
              <>
                {newClient && (
                  <SuiBox mx={2}>
                    <CustomButton
                      color="dark"
                      variant="gradient"
                      onClick={skipAndSendLink}
                      fullWidth
                      width={180}
                      disabled={!open || skipLoader}
                      title={"Skip and Send Link"}
                      loader={skipLoader}
                    />
                  </SuiBox>
                )}

                <CustomButton
                  color="dark"
                  variant="gradient"
                  onClick={handlePlanInfo}
                  fullWidth
                  width={180}
                  disabled={loader || !open}
                  title={newClient ? "Submit and Send Link" : "Submit"}
                  loader={loader}
                />
              </>
            )}
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </ModalRoot>
  );
}
