import React from "react";
import Divider from "@mui/material/Divider";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import ModalRoot from "containers/modal/ModalRoot";
import { Grid, Icon } from "@mui/material";
import SuiInput from "components/SuiInput";
import { globaleDateTimeFormatter } from "utils/helper";
import ModalHeader from "containers/modal-header";
import { updateFirstLetter } from "utils/helper";
import Createable from "containers/Select";
import SCreateable from "containers/SelectCreateable";

const calculateBalance = (totalAmount, discount, amountPaid) => {
  if (totalAmount) {
    return (
      parseFloat(totalAmount ?? 0) -
      parseFloat(discount ?? 0) -
      parseFloat(amountPaid ?? 0)
    );
  }
};

function Modal({
  open,
  item,
  handleCloseModal,
  mode,
  viewHandler,
  editHandler,
  setViewModal,
  setEditServiceClient,
}) {
  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="50%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <ModalHeader
          mode={"view"}
          title="Client details"
          subtitle={`Service details for ${updateFirstLetter(item?.name)}`}
        />

        <Grid item xs={12}>
          {mode === "view" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => {
                setViewModal(false);
                editHandler(item);
                setEditServiceClient(true);
              }}
            >
              edit
            </Icon>
          )}
          {mode === "edit" && (
            <Icon
              sx={({
                typography: { size, fontWeightBold },
                palette: { dark },
              }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
                mt: 2,
                mr: 2,
              })}
              onClick={() => viewHandler(item)}
            >
              visibility
            </Icon>
          )}
          <Icon
            sx={({
              typography: { size, fontWeightBold },
              palette: { dark },
            }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseModal}
          >
            close
          </Icon>
        </Grid>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Client Name
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.name ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Customer ID
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.customerId ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Selling Date/Time
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={globaleDateTimeFormatter(item?.purchasedAt)}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Family Name
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.familyName ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Country Code
              </SuiTypography>
              <SCreateable
                disabled
                value={item?.countryCode}
                style={{ borderRadius: "0.5rem", fontSize: 14 }}
                inputStyle={{
                  width: 190,
                  height: "1.800rem",
                  borderRadius: "0.5rem",
                }}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Phone Number
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.phoneNumber ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Email
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.email ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Address
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.address ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Gender
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.gender ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                DOB
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.dob ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Anniversary
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={
                  globaleDateTimeFormatter(item?.anniversary, "date") ?? ""
                }
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Age
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.age ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Occupation
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.occupation ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Referred By
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.referredBy ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Food preference
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.preference ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Lead From
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.leadFrom ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Package
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.serviceName}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Commencement Date
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={globaleDateTimeFormatter(item?.startDate, "date") ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Completion Date
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={globaleDateTimeFormatter(item?.completionDate, "date")}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Total Amount
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.totalAmount ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Amount Paid
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.amountPaid ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Discount
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.discount ?? ""}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Balance
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={`INR  ${
                  item?.balance
                    ? item.balance
                    : calculateBalance(
                        item?.totalAmount,
                        item?.discount,
                        item?.amountPaid
                      )?.toFixed(2)
                }`}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Payment Mode
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.paymentMode}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Complementary Service
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.complementaryService}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Notes
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.notes}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Service Type
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                value={item?.serviceInstance}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Status
              </SuiTypography>
              <SuiInput
                disabled={mode === "view"}
                type="text"
                readOnly={true}
                value={item?.status}
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={6}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Assign Dietitian
              </SuiTypography>
              <Createable
                value={item?.assignDietitian}
                disabled
                style={{
                  borderRadius: "0.5rem",
                  fontSize: 14,
                  zIndex: 999,
                }}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                inputStyle={{
                  width: 190,
                  height: "1.800rem",
                  borderRadius: "0.5rem",
                }}
                placeholder="Choose Employee Access"
              />
            </SuiBox>
          </Grid>
        </Grid>
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
