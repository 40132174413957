import React, { useState, useEffect, useCallback } from "react";
// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Grid, Icon } from "@mui/material";
import { getSelectedClientInvoices } from "services/billing";
import { toast } from "react-toastify";
import SuiInput from "components/SuiInput";
import { updateFirstLetter } from "utils/helper";
import { globaleDateTimeFormatter } from "utils/helper";
import Table from "examples/Tables/Table";
import NoData from "containers/DataNotAvailBox";
import { TailSpin } from "react-loader-spinner";
import { InvoiceHistoryRows } from "../InvoiceHistoryRows";

function Modal({ open, handleCloseModal, invoiceModalData, editHandler }) {
  const [loader, setLoader] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [serviceDetailsData, setServiceDetailsData] = useState();
  const [allRawData, setAllRawData] = useState({});
  const [rows, setRows] = useState([]); // rows for billing table

  useEffect(() => {
    let updateData = allRawData;

    if (allRawData?.productName) {
      updateData = { ...allRawData, totalAmount: allRawData?.mrp };
    }

    setServiceDetailsData({ ...updateData });
  }, [allRawData]);

  useEffect(() => {
    setRows(InvoiceHistoryRows(invoices, allRawData, editHandler));
  }, [invoices]);

  const fetchInvoices = useCallback(async () => {
    setLoader(true);
    try {
      const allInfoData = await getSelectedClientInvoices(
        invoiceModalData?.clientId,
        invoiceModalData.typeId,
        invoiceModalData?.type
      );
      setInvoices(allInfoData?.invoicePdf);
      setAllRawData(allInfoData);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(error.message);
    }
  }, [
    invoiceModalData?.clientId,
    invoiceModalData?.type,
    invoiceModalData?.typeId,
  ]);

  useEffect(() => {
    if (invoiceModalData?.clientId) {
      fetchInvoices();
    }
    if (!open) {
      setInvoices([]);
      handleCloseModal();
    }
  }, [invoiceModalData, open, fetchInvoices, handleCloseModal]);

  const columns = [
    { name: "S.no", align: "left", width: "30px" },
    {
      name: "Generated Date",
      align: "left",
      minWidth: "90px",
    },
    {
      name: "Invoice No.",
      align: "left",
      minWidth: "90px",
    },
    {
      name: "Balance",
      align: "left",
      minWidth: "120px",
    },
    {
      name: "Total Amount",
      align: "left",
      minWidth: "120px",
    },
    {
      name: "Action",
      align: "left",
      minWidth: "120px",
    },
  ];

  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="55%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SuiBox>
          <SuiTypography variant="h5">
            {invoiceModalData?.clientName}, <br />
            <span style={{ textTransform: "capitalize" }}>
              {" "}
              {invoiceModalData?.name}
            </span>{" "}
            Invoice history
          </SuiTypography>
        </SuiBox>

        <Icon
          sx={({
            typography: { size, fontWeightBold },
            palette: { dark },
          }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={handleCloseModal}
        >
          close
        </Icon>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                {serviceDetailsData?.serviceName
                  ? "Package Name"
                  : "Product Name"}
              </SuiTypography>
              <SuiInput
                type="text"
                readOnly={true}
                value={updateFirstLetter(
                  serviceDetailsData?.serviceName
                    ? serviceDetailsData?.serviceName
                    : serviceDetailsData?.productName
                )}
                disabled
              />
            </SuiBox>
          </Grid>

          {serviceDetailsData?.brand && (
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Brand
                </SuiTypography>
                <SuiInput
                  type="text"
                  readOnly={true}
                  value={updateFirstLetter(serviceDetailsData?.brand ?? "")}
                  disabled
                />
              </SuiBox>
            </Grid>
          )}

          {serviceDetailsData?.quantity && (
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Total Quantity
                </SuiTypography>
                <SuiInput
                  type="text"
                  readOnly={true}
                  value={serviceDetailsData?.quantity}
                  disabled
                />
              </SuiBox>
            </Grid>
          )}

          {serviceDetailsData?.productName ? (
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  GST
                </SuiTypography>
                <SuiInput
                  type="text"
                  readOnly={true}
                  value={`${
                    serviceDetailsData?.gst ? serviceDetailsData?.gst : 0
                  }%`}
                  disabled
                />
              </SuiBox>
            </Grid>
          ) : (
            ""
          )}

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Total Amount
              </SuiTypography>
              <SuiInput
                type="text"
                readOnly={true}
                value={serviceDetailsData?.totalAmount ?? ""}
                disabled
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Amount Paid
              </SuiTypography>
              <SuiInput
                type="text"
                readOnly={true}
                value={serviceDetailsData?.amountPaid ?? ""}
                disabled
              />
            </SuiBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <SuiBox lineHeight={1}>
              <SuiTypography mb={1} variant="h6">
                Discount
              </SuiTypography>
              <SuiInput
                type="text"
                readOnly={true}
                value={serviceDetailsData?.discount ?? 0}
                disabled
              />
            </SuiBox>
          </Grid>

          {serviceDetailsData?.paymentMode && (
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Payment Mode
                </SuiTypography>
                <SuiInput
                  type="text"
                  readOnly={true}
                  value={updateFirstLetter(
                    serviceDetailsData?.paymentMode ?? ""
                  )}
                  disabled
                />
              </SuiBox>
            </Grid>
          )}

          {serviceDetailsData?.serviceInstance && (
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Service Instance
                </SuiTypography>
                <SuiInput
                  type="text"
                  readOnly={true}
                  value={updateFirstLetter(
                    serviceDetailsData?.serviceInstance ?? ""
                  )}
                  disabled
                />
              </SuiBox>
            </Grid>
          )}

          {serviceDetailsData?.purchasedAt && (
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Purchased Date
                </SuiTypography>
                <SuiInput
                  type="text"
                  readOnly={true}
                  value={globaleDateTimeFormatter(
                    serviceDetailsData?.purchasedAt ?? ""
                  )}
                  disabled
                />
              </SuiBox>
            </Grid>
          )}

          {serviceDetailsData?.startDate && (
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Commencement Date
                </SuiTypography>
                <SuiInput
                  type="text"
                  readOnly={true}
                  value={serviceDetailsData?.startDate ?? ""}
                  disabled
                />
              </SuiBox>
            </Grid>
          )}

          {serviceDetailsData?.completionDate && (
            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Completion Date
                </SuiTypography>
                <SuiInput
                  type="text"
                  readOnly={true}
                  value={serviceDetailsData?.completionDate ?? ""}
                  disabled
                />
              </SuiBox>
            </Grid>
          )}
        </Grid>
      </SuiBox>

      <SuiBox pt={1.25} pb={3} px={3}>
        {loader ? (
          <SuiBox
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: 30,
            }}
          >
            <TailSpin color="#17c1e8" height={60} width={60} />
          </SuiBox>
        ) : invoices?.length ? (
          <Table columns={columns} rows={rows} maxRows={5} />
        ) : (
          <NoData />
        )}
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
