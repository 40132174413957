import { Link } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import { globaleDateTimeFormatter } from "utils/helper";
import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from "@mui/material";
export const InvoiceHistoryRows = (invoiceHistoryArray, rowData, editHandler) => {
  const rows = invoiceHistoryArray?.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography variant="button" fontWeight="medium">
          {index + 1}
        </SuiTypography>
      ),

      "Generated Date": (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.date, "date")}
        </SuiTypography>
      ),

      "Invoice No.": (
        <Link href={item.invoice} target="blank">
          <SuiTypography
            variant="button"
            fontWeight="medium"
            sx={{ color: "#17C1E8" }}
          >
            {item.invoiceId
              ? item.invoiceId
              : globaleDateTimeFormatter(item?.date, "date")}
          </SuiTypography>
        </Link>
      ),

      Balance: (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ color: "red" }}
        >
          INR - {item?.balance?.toFixed(2) ? item?.balance?.toFixed(2) : "N/A"}
        </SuiTypography>
      ),

      "Total Amount": (
        <SuiTypography variant="button" fontWeight="medium">
          INR - {item?.totalAmount?.toFixed(2) ? item?.totalAmount?.toFixed(2) : "N/A"}
        </SuiTypography>
      ),
      Action: (
      <Tooltip title="Edit Invoice" placement="top">
        <EditIcon
          variant="button"
          fontWeight="medium"
          style={{
            color: "#17C1E8",
            cursor: "pointer",
            paddingLeft: 8,
          }}
          onClick={() => editHandler(item, item.type)}
          fontSize="large"
        >
          Edit invoice{" "}
        </EditIcon>
      </Tooltip>
    )
    };
  });

  return rows;
};
