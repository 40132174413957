import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import Grid from "@mui/material/Grid";

// Custom styles for the Modal
import ModalRoot from "containers/modal/ModalRoot";
import { Checkbox, FormControlLabel, Icon } from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { validateEmail, validatePhone } from "utils/common";
import { SellProduct } from "store/features/clients";
import { updateSellProductStatus } from "store/features/dashboard";
import moment from "moment";
import CustomButton from "containers/CustomButton";
import GST from "components/GST/GST";
import Createable from "containers/Select";
import { getDiscountedPrice } from "utils/common";

function Modal({
  open,
  setOpenModal,
  handleCloseModal,
  selectedItem,
  uid,
  clients,
  isFetching,
  isSuccess,
  isError,
  message,
  updateSellCount,
  getClientLists,
}) {
  //default modal fields value
  const defaultHandler = useMemo(() => {
    return {
      clientId: null,
      clientName: null,
      email: null,
      phoneNumber: null,
      sellingPriceWOT: null,
      discount: 0,
      gst: 0,
      amountPaid: 0,
      qty: null,
      gstTreatment: null,
      checkGst: false,
      gstType: null,
      cgst: null,
      igst: null,
      gstComment: null,
      gstNumber: null,
      timestamp: new Date(),
      balance: 0,
    };
  }, []);
  //error handler
  const errorDefault = useMemo(() => {
    return {
      clientName: false,
      email: false,
      phoneNumber: false,
      sellingPriceWOT: false,
      discount: false,
      amountPaid: false,
      qty: false,
      gst: false,
      timestamp: false,
      gstTreatment: false,
    };
  }, []);

  const dispatch = useDispatch();

  const [formdata, setFormdata] = useState(defaultHandler);

  const [errorMessage, setErrorMessage] = useState(errorDefault);

  //for searching client details
  const [clientArray, setClientArray] = useState([]);
  useEffect(() => {
    const clientsData = clients?.map((item, index) => {
      return {
        label: `${item.name}\n(+91 ${item.phoneNumber})`,
        value: item.id,
        key: index,
        clientId: item?.id,
        clientName: item?.name,
        phoneNumber: item?.phoneNumber,
        email: item?.email,
      };
    });
    setClientArray(clientsData);
  }, [clients]);

  const [selectedClient, setSelectedClient] = useState();
  const onChangeTextName = (e) => {
    setSelectedClient(e);
    if (e.__isNew__) {
      setFormdata({
        ...formdata,
        clientId: null,
        clientName: e.value,
        phoneNumber: null,
        email: null,
      });
    } else {
      setFormdata({
        ...formdata,
        clientName: e.clientName,
        clientId: e?.clientId,
        phoneNumber: e?.phoneNumber,
        email: e?.email,
      });
    }
  };

  useEffect(() => {
    if (formdata?.checkGst) {
      setFormdata({
        ...formdata,
        gst:
          formdata.gstType.value === "IGST"
            ? formdata?.igst
            : formdata?.sgst + formdata?.cgst,
      });
    }
  }, [
    formdata?.gstType?.value,
    formdata.checkGst,
    formdata?.cgst,
    formdata?.sgst,
    formdata?.igst,
  ]);

  useEffect(() => {
    const discountedPrice = getDiscountedPrice(formdata.mrp, formdata.discount);

    let gstAmount = discountedPrice * (formdata.gst / 100);

    if (formdata?.checkGst) {
      setFormdata({
        ...formdata,
        totalAmount: discountedPrice + gstAmount,
      });
    } else {
      setFormdata({ ...formdata, totalAmount: discountedPrice });
    }
  }, [
    formdata?.gst,
    formdata.checkGst,
    formdata?.packageAmount,
    formdata?.discount,
  ]);

  //for handling selected items
  useEffect(() => {
    if (selectedItem) {
      setFormdata({
        ...defaultHandler,
        ...selectedItem,
        sellingPriceWOT: selectedItem?.mrp,
        gst: 0,
        checkGst: false,
        cgst: 0,
        igst: 0,
        gstComment: null,
        gstNumber: null,
        gstType: { label: "CGST & SGST", value: "CGST & SGST" },
      });
    }
  }, [selectedItem, defaultHandler]);

  //for submitting data
  const submitHandler = async (e) => {
    const gstNumberRegex =
      /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    const gstNumberMatch = formdata?.gstNumber?.match(gstNumberRegex);

    if (!formdata.clientName) {
      toast.error("Please enter a Client Name");
      setErrorMessage({
        ...errorMessage,
        clientName: true,
      });
      return;
    }

    if (formdata.phoneNumber) {
      if (!validatePhone(formdata.phoneNumber)) {
        toast.info("Enter a valid Phone Number");
        setErrorMessage({
          ...errorMessage,
          phoneNumber: true,
        });
        return;
      }
    } else {
      toast.info("Please enter Phone Number");
      setErrorMessage({
        ...errorMessage,
        phoneNumber: true,
      });
      return;
    }

    if (formdata.email) {
      if (!validateEmail(formdata.email)) {
        toast.info("Enter a valid Email");
        setErrorMessage({
          ...errorMessage,
          email: true,
        });
        return;
      }
    } else {
      toast.info("Please enter Email");
      setErrorMessage({
        ...errorMessage,
        email: true,
      });
      return;
    }

    if (!formdata.sellingPriceWOT) {
      toast.error("Please fill Selling Price WOT field");
      setErrorMessage({
        ...errorMessage,
        sellingPriceWOT: true,
      });
      return;
    }

    if (formdata.discount < 0) {
      toast.error("Please fill valid Discount");

      setErrorMessage({
        ...errorMessage,
        discount: true,
      });
      return;
    }

    if (
      isNaN(formdata.discount) ||
      formdata.discount === null ||
      formdata.discount === ""
    ) {
      toast.error("Please fill Discount field");

      setErrorMessage({
        ...errorMessage,
        discount: true,
      });
      return;
    }

    if (!formdata.qty) {
      toast.error("Please fill Quantity field");
      setErrorMessage({
        ...errorMessage,
        qty: true,
      });
      return;
    }

    if (!formdata.amountPaid && formdata.amountPaid !== 0) {
      toast.error("Please fill Amount Paid field");
      setErrorMessage({
        ...errorMessage,
        amountPaid: true,
      });
      return;
    }

    if (formdata.amountPaid < 0) {
      toast.info("Value can't be negative");
      setErrorMessage({
        ...errorMessage,
        amountPaid: true,
      });
      return;
    }

    if (parseInt(formdata.qty) === 0) {
      toast.error("Please fill QTY. field");
      setErrorMessage({
        ...errorMessage,
        qty: true,
      });
      return;
    }

    if (!formdata.timestamp) {
      toast.error("Please fill Date/Time field");
      setErrorMessage({
        ...errorMessage,
        timestamp: true,
      });
      return;
    }

    if (formdata?.checkGst) {
      if (!formdata.gstTreatment) {
        toast.error("Please fill GST treatment field");
        setErrorMessage({
          ...errorMessage,
          cgst: true,
        });
        return;
      }

      if (
        !formdata?.gstNumber?.length ||
        formdata?.gstNumber === undefined ||
        formdata?.gstNumber === null
      ) {
        return toast.error("Please fill GST number!!");
      } else if (!gstNumberMatch) {
        return toast.error("Invalid GST number!!");
      }

      if (formdata?.gstType.value === "IGST") {
        if (!formdata.igst) {
          toast.error("Please fill IGST field");
          setErrorMessage({
            ...errorMessage,
            igst: true,
          });
          return;
        }
      } else {
        if (!formdata.sgst) {
          toast.error("Please fill SGST field");
          setErrorMessage({
            ...errorMessage,
            sgst: true,
          });
          return;
        }

        if (!formdata.cgst) {
          toast.error("Please fill CGST field");
          setErrorMessage({
            ...errorMessage,
            cgst: true,
          });
          return;
        }
      }
    }

    const balance = calculateBalance();

    if (balance < 0) {
      return toast.error("Balance can't be negative!");
    }

    setFormdata((prevFormdata) => {
      const updatedFormdata = { ...prevFormdata, balance };
      return updatedFormdata;
    });

    dispatch(SellProduct({ formdata, uid }));
  };

  //for calc selling price with tax
  const calcSellingPrice = () => {
    const discountedPrice = getDiscountedPrice(formdata.mrp, formdata.discount);
    let gstAmount = discountedPrice * (formdata.gst / 100);
    return discountedPrice + gstAmount;
  };

  useEffect(() => {
    if (isSuccess && open) {
      handleCloseModal();
      setTimeout(() => {
        setSelectedClient(null);
        getClientLists();
        updateSellCount(formdata.clientId, formdata.qty);
        dispatch(
          updateSellProductStatus({ id: formdata.id, quantity: formdata.qty })
        );
        setFormdata({
          ...defaultHandler,
        });
        setErrorMessage({
          ...errorDefault,
        });
        toast.success(message);
      }, 100);
    }
  }, [
    isSuccess,
    handleCloseModal,
    dispatch,
    isError,
    errorDefault,
    formdata.clientId,
    formdata.qty,
    formdata.id,
    updateSellCount,
    defaultHandler,
    message,
    open,
    getClientLists,
  ]);

  //for calculating total balance

  const calculateBalance = () => {
    const sellingPrice =
      parseFloat(calcSellingPrice()) * parseInt(formdata.qty) -
      parseFloat(formdata.amountPaid ?? 0);

    return sellingPrice;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "discount" || name === "amountPaid") {
      updatedValue = value > 0 ? value : 0;
    }
    if (name === "discount") {
      const discount = parseFloat(updatedValue);
      const sellingPrice = parseFloat(formdata?.sellingPriceWOT);
      const amountPaid = parseFloat(formdata?.amountPaid);
      const balance = sellingPrice - amountPaid;
      const roundedDiscountValue = Math.round(discount * 100) / 100;
      const roundedSellingPrice = Math.round(sellingPrice * 100) / 100;
      const roundedBalance = Math.round(balance * 100) / 100;
      if (roundedDiscountValue > roundedSellingPrice) {
        toast("Can't exceed selling price!");
        return;
      } else if (roundedDiscountValue > roundedBalance) {
        toast("Can't exceed balance amount!");
        return;
      }
    }
    if (name === "amountPaid") {
      const amountPaid = parseFloat(updatedValue);
      const roundedAmountPaid = Math.round(amountPaid * 100) / 100;
      const totalAmount =
        parseFloat(calcSellingPrice()) * parseInt(formdata?.qty);
      const roundedTotalAmount = Math.round(totalAmount * 100) / 100;
      if (roundedAmountPaid > roundedTotalAmount) {
        toast("Can't exceed balance amount!");
        return;
      }
    }
    setFormdata((prev) => ({
      ...prev,
      [name]: updatedValue,
    }));
  };
  console.log(formdata);
  return (
    <ModalRoot
      variant="permanent"
      ownerState={{ openConfigurator: open }}
      width="55%"
    >
      <SuiBox
        style={{ whiteSpace: "normal !important" }}
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SuiBox>
          <SuiTypography variant="h5">
            {selectedItem?.productName}
          </SuiTypography>
          <SuiTypography variant="body2" color="text">
            Provide client details & sell
          </SuiTypography>
        </SuiBox>

        <Icon
          sx={({
            typography: { size, fontWeightBold },
            palette: { dark },
          }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={() => {
            handleCloseModal();
            setTimeout(() => {
              setSelectedClient(null);
              setFormdata({
                ...defaultHandler,
              });
              setErrorMessage({
                ...errorDefault,
              });
            }, 500);
          }}
        >
          close
        </Icon>
      </SuiBox>

      <Divider />

      <SuiBox pt={1.25} pb={3} px={3}>
        <SuiBox>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox mt={3} lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Sell To
                </SuiTypography>
                <Createable
                  options={clientArray ?? []}
                  error={errorMessage?.clientName}
                  value={selectedClient}
                  style={{
                    borderRadius: "0.5rem",
                    fontSize: 14,
                    whiteSpace: "word-break",
                  }}
                  inputStyle={{
                    width: 190,
                    height: "1.800rem",
                    borderRadius: "0.5rem",
                  }}
                  placeholder="Choose client"
                  onChange={(e) => {
                    setFormdata({
                      ...formdata,
                      clientName: e,
                    });

                    setErrorMessage({
                      ...errorMessage,
                      clientName: false,
                    });

                    onChangeTextName(e);
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mt={3} lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Phone Number
                </SuiTypography>
                <SuiInput
                  type="text"
                  name="phoneNumber"
                  className="hide-arrow"
                  disabled={formdata?.clientId !== null}
                  error={errorMessage?.phoneNumber}
                  value={formdata.phoneNumber ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^\d+$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }

                    if (val.length > 10) {
                      toast.info("Invalid phone number");
                      return;
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mt={3} lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Email
                </SuiTypography>
                <SuiInput
                  type="email"
                  name="email"
                  disabled={formdata?.clientId !== null}
                  error={errorMessage?.email}
                  value={formdata.email ?? ""}
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
        <SuiBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <SuiBox mr={2}>
                <SuiTypography variant="h6">MRP</SuiTypography>

                <SuiTypography variant="caption">
                  INR {formdata.mrp?.toFixed(2)}
                </SuiTypography>
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mr={2}>
                <SuiTypography mb={1} variant="h6">
                  Selling Price WOT
                </SuiTypography>

                <SuiInput
                  type="number"
                  error={errorMessage?.sellingPriceWOT}
                  placeholder=""
                  name="sellingPriceWOT"
                  value={formdata.sellingPriceWOT ?? ""}
                  onChange={(e) => {
                    let numberRegex = /^[0-9][0-9]{0,8}?(\.[0-9]{1,2})?$/;
                    let val = e.target.value;
                    let match_ = val.match(numberRegex);
                    if (!match_ && val) {
                      return false;
                    }
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseFloat(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mr={2}>
                <SuiTypography mb={1} variant="h6">
                  HSN/SAC
                </SuiTypography>

                <SuiInput
                  disabled
                  type="number"
                  placeholder=""
                  name="qty"
                  error={errorMessage?.hsnCode}
                  value={formdata?.hsnCode?.value ?? "N/A"}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox>
                <SuiTypography mb={1} variant="h6">
                  Selling Price WT
                </SuiTypography>
                <SuiTypography variant="caption">
                  INR {!isNaN(calcSellingPrice()) ? calcSellingPrice() : "N/A"}
                </SuiTypography>
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox>
                <SuiTypography mb={1} variant="h6">
                  Discount
                </SuiTypography>

                <SuiInput
                  type="number"
                  placeholder=""
                  name="discount"
                  error={errorMessage?.discount}
                  value={formdata?.discount ?? 0}
                  onChange={handleInputChange}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox mr={2}>
                <SuiTypography mb={1} variant="h6">
                  QTY.
                </SuiTypography>

                <SuiInput
                  type="number"
                  placeholder=""
                  name="qty"
                  error={errorMessage?.qty}
                  value={formdata?.qty ?? ""}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (formdata.availableQuantity < val) {
                      toast.info(
                        `Available quantity are only ${formdata.availableQuantity}, can't exceed`
                      );
                      setFormdata({
                        ...formdata,
                        qty: 0,
                      });
                      return;
                    }

                    if (val < 0) {
                      toast.info("Value can't be negative");
                      setFormdata({
                        ...formdata,
                        qty: 0,
                      });
                    }

                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: parseInt(e.target.value),
                    });
                  }}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Amount Paid
                </SuiTypography>
                <SuiInput
                  type="number"
                  name="amountPaid"
                  error={errorMessage?.amountPaid}
                  value={formdata.amountPaid ?? ""}
                  onChange={handleInputChange}
                />
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Balance
                </SuiTypography>
                <SuiTypography mb={1} variant="h7" style={{ color: "red" }}>
                  INR{" "}
                  {isNaN(calculateBalance())
                    ? "-"
                    : calculateBalance().toFixed(2) ?? "-"}
                </SuiTypography>
              </SuiBox>
            </Grid>

            <Grid item xs={12} lg={4}>
              <SuiBox lineHeight={1}>
                <SuiTypography mb={1} variant="h6">
                  Date/time
                </SuiTypography>
                <SuiInput
                  type="datetime-local"
                  name="timestamp"
                  placeholder=""
                  error={errorMessage?.timestamp}
                  value={
                    formdata.timestamp
                      ? moment(formdata.timestamp).format("YYYY-MM-DDTkk:mm")
                      : moment().format("YYYY-MM-DDTkk:mm")
                  }
                  onChange={(e) => {
                    setErrorMessage({
                      ...errorMessage,
                      [e.target.name]: false,
                    });
                    setFormdata({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>

        <SuiBox mt={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formdata?.checkGst}
                onChange={() => {
                  setFormdata({
                    ...formdata,
                    checkGst: !formdata?.checkGst,
                    igst: 0,
                    sgst: 0,
                    cgst: 0,
                  });
                }}
              />
            }
            label="GST"
            sx={{ marginLeft: 0, width: "fit-content" }}
          />
        </SuiBox>

        {formdata?.checkGst && (
          <SuiBox mt={3}>
            <GST
              formdata={formdata}
              setFormdata={setFormdata}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
              gstTreatment
            />
          </SuiBox>
        )}
        <Divider />
        <SuiBox mt={3} mb={2} display="flex" justifyContent="center">
          <SuiBox mb={2} width="32%">
            <CustomButton
              color="dark"
              variant="gradient"
              onClick={submitHandler}
              disabled={isFetching || !open}
              fullWidth
              width={180}
              title={"Sell Product"}
              loader={isFetching}
            />
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </ModalRoot>
  );
}

export default Modal;
