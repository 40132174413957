import { WhatsApp } from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import TableAction from "containers/table-action";
import { globaleDateTimeFormatter } from "utils/helper";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import RestorePageIcon from "@mui/icons-material/RestorePage";

const calculateBalance = (totalAmount, discount, amountPaid) => {
  if (totalAmount) {
    return (
      parseFloat(totalAmount ?? 0) -
      parseFloat(discount ?? 0) -
      parseFloat(amountPaid ?? 0)
    );
  }
};

export const serviceRow = (
  serviceArray,
  navigate,
  setInvoiceDataHandler,
  viewHandler,
  loading,
  formdata,
  setFormdata,
  editHandler
) => {
  const rows = serviceArray.map((item, index) => {
    return {
      "S.no": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ paddingLeft: 14 }}
        >
          {index + 1}
        </SuiTypography>
      ),

      "Client Name": (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{
            color: "#17C1E8",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(`/clients/${item.clientId}`);
          }}
        >
          {item.name}
        </SuiTypography>
      ),

      "Customer ID": (
        <SuiTypography variant="button" fontWeight="medium">
          {item.customerId ?? "N/A"}
        </SuiTypography>
      ),

      "Date/Time": (
        <SuiTypography variant="button" fontWeight="medium">
          {globaleDateTimeFormatter(item?.createdAt)}
        </SuiTypography>
      ),

      Phone: (
        <>
          <SuiTypography variant="button" fontWeight="medium">
            {item?.phoneNumber}
          </SuiTypography>
          <IconButton
            size="small"
            style={{ padding: "3px", marginLeft: "5px" }}
            onClick={() => {
              window.open(`https://wa.me/${item?.phoneNumber}`, "_blank");
            }}
          >
            <WhatsApp style={{ color: "#075e54" }} />
          </IconButton>
        </>
      ),
      Email: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.email}
        </SuiTypography>
      ),

      Package: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.serviceName}
        </SuiTypography>
      ),

      "Total Amount": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.totalAmount}
        </SuiTypography>
      ),

      "Amount Paid": (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.amountPaid}
        </SuiTypography>
      ),

      Discount: (
        <SuiTypography variant="button" fontWeight="medium">
          {item?.discount}
        </SuiTypography>
      ),

      Balance: (
        <SuiTypography
          variant="button"
          fontWeight="medium"
          style={{ color: "red" }}
        >
          INR{" "}
          {item?.totalBalance ||
            calculateBalance(
              item.totalAmount,
              item.discount,
              item.amountPaid
            )?.toFixed(2)}
        </SuiTypography>
      ),

      Action: (
        <SuiBox style={{ display: "flex", alignItems: "center" }}>
          {/* <TableAction
            style={{ marginLeft: "7px" }}
            viewHandler={() => viewHandler(item, "consultation")}
          /> */}
          {loading === index ? (
            <CircularProgress color="success" size={"1rem"} />
          ) : (
            <Tooltip title="Create Invoice" placement="top">
              <NoteAddIcon
                variant="button"
                fontWeight="medium"
                style={{
                  color: "#17C1E8",
                  cursor: "pointer",
                  paddingLeft: 8,
                }}
                onClick={() => editHandler(item, "consultation")}
                fontSize="large"
              >
                Create invoice{" "}
              </NoteAddIcon>
            </Tooltip>
          )}
          <Tooltip title="Invoice History" placement="top">
            <RestorePageIcon
              variant="button"
              fontWeight="medium"
              style={{
                color: "#17C1E8",
                cursor: "pointer",
                paddingLeft: 8,
              }}
              onClick={() => {
                setInvoiceDataHandler(
                  item.name,
                  item.serviceName,
                  item.id,
                  "consultation",
                  item.clientId
                );
              }}
              fontSize="large"
            >
              Invoice history
            </RestorePageIcon>
          </Tooltip>
        </SuiBox>
      ),
    };
  });
  return rows;
};
