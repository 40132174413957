import React, { useState, useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import * as XLSX from "xlsx";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import Table from "examples/Tables/Table";
import Searchbar from "containers/Searchbar";
import moment from "moment";
import NoData from "containers/DataNotAvailBox";
import { productRow } from "./rows.js";
import ProductSearch from "./search";
import ProductStat from "./stat";
import { useNavigate } from "react-router-dom";
import {
  timestampConversion,
  calculateProductTotalBalance,
  calculateProductFinalPrice,
} from "utils/common";
import { TailSpin } from "react-loader-spinner";
import { sortData } from "utils/sort.js";

function ProductTable({
  productBilling,
  products,
  isSuccess,
  isFetching,
  loader,
  user,
  setOpenNoteModal,
  setInvoiceDataHandler,
  viewHandler,
  downloadAndStorePdf,
  editHandler,
}) {
  let navigate = useNavigate(); //for navigation
  const [productOptions, setProductOptions] = useState([]); //filtering wrt products
  const [loading, setLoading] = useState(null);
  const [formdata, setFormdata] = useState({});
  const [errorMessage, setErrorMessage] = useState({});

  // options for dropdown of product filter
  useEffect(() => {
    let productArray = products.map((item) => {
      return {
        value: item?.productName,
        label: item?.productName,
      };
    });
    setProductOptions(productArray);
  }, [products]);

  const [rows, setRows] = useState([]); // rows for product table

  const [searchVal, setsearchVal] = useState(""); //storing search input text
  const [productArray, setproductArray] = useState([]);

  // for sorting
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "",
    sortKey: "",
  });

  const columns = [
    { name: "S.no", align: "left", width: "30px" },
    {
      name: "Client Name",
      align: "left",
      minWidth: "150px",
      sort: true,
      sortKey: "name",
    },
    // { name: "Customer ID", align: "left", minWidth: '105px' },
    {
      name: "Selling Date/Time",
      align: "left",
      minWidth: "90px",
      sort: true,
      sortKey: "purchasedAt",
    },
    {
      name: "Product",
      align: "left",
      minWidth: "130px",
      sort: true,
      sortKey: "productName",
    },
    // { name: "Email", align: "left", minWidth: '120px', sort: true, sortKey: "email" },
    // { name: "Brand", align: "left", minWidth: '120px' },
    // { name: "Discount", align: "left", minWidth: '120px' },
    { name: "Balance", align: "left", minWidth: "80px" },
    { name: "Quantity", align: "left", minWidth: "80px" },
    // { name: "Total Amount", align: "left", minWidth: '120px' },
    // { name: "Amount Paid", align: "left", minWidth: '120px' },
    // { name: "Balance", align: "left", minWidth: '120px' },
    { name: "Action", align: "left", minWidth: "120px" },
  ];

  useEffect(() => {
    const sortedRows = sortData(productArray, sortConfig);

    setRows(
      productRow(
        sortedRows,
        navigate,
        setInvoiceDataHandler,
        viewHandler,
        loading,
        formdata,
        setFormdata,
        editHandler
      )
    );
  }, [
    productArray,
    navigate,
    user,
    setOpenNoteModal,
    setInvoiceDataHandler,
    sortConfig,
    viewHandler,
    downloadAndStorePdf,
    loading,
    setLoading,
  ]);

  const handleSort = (name, sortKey) => {
    let direction = "asc";
    if (sortConfig.key === name && sortConfig.direction === "asc") {
      direction = "desc";
    }

    setSortConfig({ key: name, direction, sortKey });
  };

  const [filterQuery, setFilterQuery] = useState({
    product: null,
    fromDate: null,
    toDate: null,
  }); // for filtering

  const filterHandler = () => {
    setsearchVal("");
    let timestamp;
    let filterData = productBilling.filter((item) => {
      if (filterQuery.product) {
        if (filterQuery.product === "all") {
          setFilterQuery({ ...filterQuery, product: null });
        } else if (filterQuery.product !== item["productName"]) {
          return false;
        }
      }

      if (filterQuery.fromDate) {
        timestamp = item.purchasedAt;
        timestamp = timestamp.seconds
          ? timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000
          : timestamp;
        timestamp = moment.utc(timestamp);
        if (
          new Date(filterQuery.fromDate) >
          new Date(moment(timestamp).format("YYYY-MM-DD"))
        ) {
          return false;
        }
      }

      if (filterQuery.toDate) {
        timestamp = item.purchasedAt;
        timestamp = timestamp.seconds
          ? timestamp?.nanoseconds / 1000000 + timestamp?.seconds * 1000
          : timestamp;
        timestamp = moment.utc(timestamp);
        if (
          moment
            .utc(moment(filterQuery.toDate).add(24, "hours"))
            .isBefore(timestamp)
        ) {
          return false;
        }
      }
      return item;
    });
    setproductArray(filterData);
  };

  //for resetting filters
  const resetHandler = useCallback(() => {
    setFilterQuery({
      product: null,
      fromDate: null,
      toDate: null,
    });
    setproductArray(productBilling);
  }, [productBilling]);

  //use for search with unique code field
  useEffect(() => {
    if (!searchVal) {
      resetHandler();
      setproductArray(productBilling);
    } else {
      resetHandler();
      let filteredProduct = productBilling.filter((s) => {
        let name = s?.name;
        return name.toLowerCase().includes(searchVal.toLowerCase());
      });
      setproductArray((e) => filteredProduct);
    }
  }, [searchVal, isSuccess, isFetching, productBilling, resetHandler]);

  const excelDownload = () => {
    let updatedData = productBilling.map((item, index) => {
      let time1 = item?.purchasedAt?.seconds
        ? moment(timestampConversion(item?.purchasedAt))
            .local()
            .format("MMMM Do YYYY, h:mm a")
        : moment(item?.purchasedAt).local().format("MMMM Do YYYY, h:mm a");
      return [
        index + 1,
        item.name,
        item.customerId ?? "N/A",
        time1,
        item.productName,
        item.email,
        item.brand,
        item.discount,
        item.quantity,
        calculateProductFinalPrice(
          item.quantity,
          item.sellingPriceWOT,
          item.discount,
          item.gst
        ),
        item.amountPaid,
        calculateProductTotalBalance(
          item.sellingPriceWOT,
          item.quantity,
          item.discount,
          item.gst,
          item.amountPaid
        ),
      ];
    });
    let excelData = [
      [
        "id",
        "CLIENT NAME",
        "CUSTOMER ID",
        "SELLING DATE/TIME",
        "PRODUCT",
        "EMAIL",
        "BRAND",
        "DISCOUNT",
        "QUANTITY",
        "TOTAL AMOUNT",
        "AMOUNT PAID",
        "BALANCE",
      ],
      ...updatedData,
    ];

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "MySheet");
    XLSX.writeFile(workbook, "Client_List_Products.xlsx");
  };

  return (
    <SuiBox mb={3}>
      <Card style={{ overflow: "inherit" }}>
        <SuiBox
          className="search-div"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          p={3}
          pb={0}
          flexDirection={{ xs: "column-reverse", lg: "row" }}
          gap={1}
        >
          <SuiTypography
            variant="button"
            fontWeight="regular"
            color={"dark"}
            sx={{ mb: 0.75 }}
          >
            Below are the clients, who have purchased your products, you can
            generate the invoice and view clients.
          </SuiTypography>
          <SuiBox
            className="search-box-div"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <Searchbar
              placeholder={"Search Client Name"}
              searchVal={searchVal}
              searchHandler={(e) => {
                setsearchVal((_) => e);
              }}
              style_={{ marginRight: 25, marginBottom: 10 }}
              clearHandler={() => {
                setsearchVal("");
              }}
            />
          </SuiBox>
        </SuiBox>

        <ProductSearch
          setFilterQuery={setFilterQuery}
          filterHandler={filterHandler}
          filterQuery={filterQuery}
          resetHandler={resetHandler}
          productOptions={productOptions}
          excelDownload={excelDownload}
        />

        <ProductStat products={productArray} />

        <SuiBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loader ? (
            <SuiBox
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: 30,
              }}
            >
              <TailSpin color="#17c1e8" height={60} width={60} />
            </SuiBox>
          ) : rows.length ? (
            <Table
              columns={columns}
              rows={rows}
              handleSort={handleSort}
              sortConfig={sortConfig}
            />
          ) : (
            <NoData />
          )}
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

export default ProductTable;
