import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { formatDatev3, createInvoiceId, extractUsername } from "utils/common";
import styles from "./style";
import facebookIcon from "assets/images/fb.png";
import instagramIcon from "assets/images/insta.png";
import twitterIcon from "assets/images/twitter.png";

const Header = ({ user }) => {
  return (
    <View style={styles.header}>
      <View style={styles.details}>
        <Image style={styles.logoImage} src={user.logo} />
      </View>

      <Text style={styles.title}> INVOICE</Text>
    </View>
  );
};

const HeaderContent = ({ user, bill }) => {
  return (
    <View style={styles.headerContent}>
      <View>
        <Text style={styles.company}>{user.name}</Text>
        <Text style={{ ...styles.company, ...styles.address }}>
          {user.address}
        </Text>
        <Text style={styles.company}>{user.phoneNumber}</Text>
        <Text style={styles.company}>{user.email}</Text>
        <Text style={styles.company}>
          GSTIN: {user.gstNumber ? user.gstNumber : "-"}
        </Text>
      </View>
      <View>
        <View style={styles.headerRow}>
          <Text style={styles.invoice}>INVOICE #</Text>
          <Text style={styles.date}>DATE</Text>
        </View>
        <View style={styles.dataRow}>
          <Text style={{ ...styles.invoice, ...styles.padding }}>
          {bill.invoiceId ? bill.invoiceId : createInvoiceId()}
          </Text>
          <Text style={{ ...styles.date, ...styles.padding }}>
            {formatDatev3(bill.invoiceDate)}
          </Text>
        </View>
      </View>
    </View>
  );
};

const CustomerInfo = ({ bill }) => {
  return (
    <View style={styles.mainHeader}>
      <View>
        <View style={styles.headerRow}>
          <Text style={styles.text}>BILL TO</Text>
        </View>
        <View>
          <Text style={styles.padding}>{bill.name}</Text>
          <Text style={styles.padding}>{bill.address}</Text>
          <Text style={styles.padding}>{bill.phoneNumber}</Text>
          <Text style={styles.padding}>{bill.email}</Text>
          {bill.checkGst && (
            <>
              <Text style={styles.padding}>
                GST Treatment: {bill.gstTreatment.value}
              </Text>
              <Text style={{ ...styles.padding, marginBottom: 15 }}>
                GSTIN: {bill.gstNumber}
              </Text>
            </>
          )}
        </View>
      </View>
    </View>
  );
};

const ServiceRowv1 = ({ bill }) => {
  return (
    <View>
      {bill?.rowData?.map((row, index) => (
        <View key={index} style={styles.mainDataRow}>
          <View
            style={{
              ...styles.desc,
              ...styles.borderRightTop,
              width: "40%",
            }}
          >
            <Text>{row?.serviceName}</Text>
          </View>

          <View
            style={{
              ...styles.qty,
              ...styles.borderRightTop,
              width: "15%",
            }}
          >
            <Text>{bill?.hsnCode?.value ?? "N/A"}</Text>
          </View>

          <View
            style={{
              ...styles.qty,
              ...styles.borderRightTop,
              width: "15%",
            }}
          >
            <Text>{row?.quantity}</Text>
          </View>
          <View
            style={{
              ...styles.qty,
              ...styles.borderRightTop,
              width: "15%",
            }}
          >
            <Text>{parseFloat(row?.rate)?.toFixed(2)}</Text>
          </View>
          <View
            style={{
              ...styles.qty,
              ...styles.borderRightTop,
              width: "15%",
            }}
          >
            <Text>{row?.gst}%</Text>
          </View>
          <View
            style={{
              ...styles.amount,
              ...styles.borderRightTop,
              width: "20%",
            }}
          >
            <Text>{row?.amount?.toFixed(2)}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const ProductRowv1 = ({ bill }) => {
  return (
    <View>
      {bill?.rowData?.map((row, index) => (
        <View style={styles.mainDataRow} key={index}>
          <View
            style={{
              ...styles.desc,
              ...styles.borderRightTop,
              width: "40%",
            }}
          >
            <Text>{row.productName}</Text>
          </View>

          <View
            style={{
              ...styles.qty,
              ...styles.borderRightTop,
              width: "15%",
            }}
          >
            <Text>{row?.hsnCode?.value ?? "N/A"}</Text>
          </View>

          <View
            style={{
              ...styles.qty,
              ...styles.borderRightTop,
              width: "15%",
            }}
          >
            <Text>{row.quantity}</Text>
          </View>

          <View
            style={{
              ...styles.unit,
              ...styles.borderRightTop,
              width: "10%",
            }}
          >
            <Text>{row.rate}</Text>
          </View>

          <View
            style={{
              ...styles.unit,
              ...styles.borderRightTop,
              width: "10%",
            }}
          >
            <Text>{row.gst}%</Text>
          </View>

          <View
            style={{
              ...styles.amount,
              ...styles.borderRightTop,
              width: "20%",
            }}
          >
            <Text>{row.amount}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const ConsultationRowv1 = ({ bill }) => {
  return (
    <View>
      {bill?.rowData?.map((row, index) => (
        <View style={styles.mainDataRow} key={index}>
          <View
            style={{
              ...styles.desc,
              ...styles.borderRightTop,
              width: "40%",
            }}
          >
            <Text>{row.serviceName}</Text>
          </View>

          <View
            style={{
              ...styles.qty,
              ...styles.borderRightTop,
              width: "15%",
            }}
          >
            <Text>{row?.hsnCode?.value ?? "N/A"}</Text>
          </View>

          <View
            style={{
              ...styles.qty,
              ...styles.borderRightTop,
              width: "15%",
            }}
          >
            <Text>{"-"}</Text>
          </View>

          <View
            style={{
              ...styles.unit,
              ...styles.borderRightTop,
              width: "10%",
            }}
          >
            <Text>{row.rate}</Text>
          </View>

          <View
            style={{
              ...styles.unit,
              ...styles.borderRightTop,
              width: "10%",
            }}
          >
            <Text>{row.gst}%</Text>
          </View>

          <View
            style={{
              ...styles.amount,
              ...styles.borderRightTop,
              width: "20%",
            }}
          >
            <Text>{row.amount}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const ConsultationRowv2 = ({ bill }) => {
  return (
    <View style={styles.mainDataRow}>
      <Text style={{ ...styles.desc, ...styles.borderRightTop, width: "40%" }}>
        Discount
      </Text>
      <Text style={{ ...styles.qty, ...styles.borderRightTop, width: "20%" }}>
        {" "}
        -{" "}
      </Text>
      <Text style={{ ...styles.unit, ...styles.borderRightTop, width: "20%" }}>
        {"-"}
      </Text>
      <Text
        style={{ ...styles.amount, ...styles.borderRightTop, width: "20%" }}
      >
        {parseFloat(bill.discount)}
      </Text>
    </View>
  );
};

const ProductServiceSection = ({ bill, type }) => {
  if (type === "consultation") {
    return (
      <>
        <View style={styles.mainRow}>
          <Text style={{ ...styles.desc, width: "30%" }}>
            Items & Description
          </Text>
          <Text style={{ ...styles.desc, width: "15%" }}>HSN/SAC</Text>
          <Text style={{ ...styles.qty, width: "15%" }}>Qty</Text>
          <Text style={{ ...styles.unit, width: "10%" }}>Price</Text>
          <Text style={{ ...styles.unit, width: "10%" }}>GST</Text>
          <Text
            style={{
              ...styles.amount,
              ...styles.amountHeader,
              width: "20%",
            }}
          >
            Amount
          </Text>
        </View>

        <ConsultationRowv1 bill={bill} />
        <ConsultationRowv2 bill={bill} />
      </>
    );
  }
  return (
    <View style={styles.main}>
      {!bill.productName ? (
        <>
          <View style={styles.mainRow}>
            <Text style={{ ...styles.desc, width: "40%" }}>
              Items & Description
            </Text>
            <Text style={{ ...styles.desc, width: "15%" }}>HSN/SAC</Text>
            <Text style={{ ...styles.qty, width: "15%" }}>Qty</Text>
            <Text style={{ ...styles.unit, width: "10%" }}>Price</Text>
            <Text style={{ ...styles.unit, width: "15%", textAlign: "center" }}>
              GST
            </Text>
            <Text
              style={{
                ...styles.amount,
                width: "20%",
                ...styles.amountHeader,
              }}
            >
              Amount
            </Text>
          </View>

          <ServiceRowv1 bill={bill} />
        </>
      ) : (
        <>
          <View style={styles.mainRow}>
            <Text style={{ ...styles.desc, width: "40%" }}>
              Items & Description
            </Text>
            <Text style={{ ...styles.desc, width: "15%" }}>HSN/SAC</Text>
            <Text style={{ ...styles.qty, width: "15%" }}>Qty</Text>
            <Text style={{ ...styles.unit, width: "10%" }}>Price</Text>
            <Text style={{ ...styles.unit, width: "15%", textAlign: "center" }}>
              GST
            </Text>
            <Text
              style={{
                ...styles.amount,
                width: "20%",
                ...styles.amountHeader,
              }}
            >
              Amount
            </Text>
          </View>

          <ProductRowv1 bill={bill} />
        </>
      )}
    </View>
  );
};

const InvoiceAmount = ({ bill }) => {
  return (
    <View style={styles.invoiceAmount}>
      <View style={styles.blank} />
      <View style={styles.summaryTitle}>
        <Text>Subtotal</Text>
        <Text>Total Tax Rate(%)</Text>
        <Text>Total Tax Amount</Text>
        <Text style={styles.total}>Total</Text>
        <Text>Discount</Text>
        <Text>Amount Paid</Text>
        <Text style={styles.balance}>Balance</Text>
      </View>

      <View style={styles.summaryValue}>
        <Text>{bill?.totalAmountBeforeTax?.toFixed(2)}</Text>
        <Text>{bill?.totalTaxRate?.toFixed(2)}</Text>
        <Text>{bill?.totalTaxAmount?.toFixed(2)}</Text>
        <Text>{bill?.totalAmount?.toFixed(2)}</Text>
        <Text>{bill?.discount}</Text>
        <Text>{bill?.amountPaid}</Text>
        <Text>{bill?.balance?.toFixed(2)}</Text>
        <Text style={styles.totalVal}>₹ {bill?.balance?.toFixed(2)}</Text>
      </View>
    </View>
  );
};

const Socials = ({ user, note, bill }) => {
  // Extract usernames from social media URLs
  const facebookUsername = user.fb ? extractUsername(user.fb) : "";
  const twitterUsername = user.twitter ? extractUsername(user.twitter) : "";
  const instagramUsername = user.instagram
    ? extractUsername(user.instagram)
    : "";
  return (
    <View style={styles.footer}>
      {
        <View style={styles.termText}>
          <Text style={styles.termsText1}>Terms & conditions: </Text>
          {bill?.termsAndCondition ? (
            <Text style={styles.termsText2}>{bill.termsAndCondition}</Text>
          ) : (
            note && note !== "" && <Text style={styles.termsText2}>{note}</Text>
          )}
        </View>
      }
      <Text style={styles.footerText}>Thank you for your business!</Text>
      <View style={styles.socialMedia}>
        {user.fb && (
          <View style={styles.socialMedia}>
            <Image src={facebookIcon} style={styles.icon} />
            <Text style={styles.username}>@{facebookUsername}</Text>
          </View>
        )}
        {user.twitter && (
          <View style={styles.socialMedia}>
            <Image src={twitterIcon} style={styles.icon} />
            <Text style={styles.username}>@{twitterUsername}</Text>
          </View>
        )}
        {user.instagram && (
          <View style={styles.socialMedia}>
            <Image src={instagramIcon} style={styles.icon} />
            <Text style={styles.username}>@{instagramUsername}</Text>
          </View>
        )}
      </View>

      {user?.signature && user.signature !== "" && (
        <View style={styles.termSig}>
          <View>
            <Image style={styles.signature} src={user.signature} />
            <Text style={styles.termsText1}>Authority Signature</Text>
          </View>
        </View>
      )}
    </View>
  );
};

const Footer = ({ user }) => {
  return (
    <View style={styles.contact}>
      <Text>If you have questions about this invoice, please contact</Text>
      <Text style={styles.details}>
        {user.name}, {user.phoneNumber}, {user.email}
      </Text>
      {user.qr && user.qr !== "" && <Image style={styles.qr} src={user.qr} />}
    </View>
  );
};

// Create Document Component
const MyDocument = ({ user, bill, note, type }) => {
  return (
    <Document>
      <Page style={styles.page} size="A3">
        <Header user={user} />
        <HeaderContent user={user} bill={bill} />
        <CustomerInfo bill={bill} />
        <ProductServiceSection bill={bill} type={type} />
        <InvoiceAmount bill={bill} />
        <Footer user={user} />
        <Socials user={user} bill={bill} note={note} />
      </Page>
    </Document>
  );
};

export default MyDocument;
