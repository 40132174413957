import { useCallback, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ProductTable from "./sub-components/product";
import ServiceTable from "./sub-components/service";

import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { userSelector } from "store/features/authSlice";
import { ClientSelector, getClients, clearState } from "store/features/clients";
import {
  DashboardSelector,
  getProductInventory,
  getServiceInventory,
  getConsultationServices,
} from "store/features/dashboard";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Tooltip from "@mui/material/Tooltip";
import { Icon } from "@mui/material";
import ConsultationTable from "./sub-components/consultation";
import { invoiceBillingPdf } from "utils/pdf";
import InvoiceModal from "./sub-components/modal/invoice";
import ServiceModal from "./sub-components/modal/service";
import ProductModal from "./sub-components/modal/product";
import ConsultationModal from "./sub-components/modal/consultation";
import Expense from "pages/expense";

function Billing() {
  const {
    uid,
    phoneNumber,
    name,
    email,
    address,
    logo,
    qr,
    signature,
    instagram,
    fb,
    twitter,
    termsAndConditions,
    gstNumber,
  } = useSelector(userSelector);

  const user = {
    name,
    email,
    phoneNumber,
    address,
    logo,
    qr,
    signature,
    instagram,
    fb,
    twitter,
    gstNumber,
    termsAndConditions,
  };
  const [loader, setLoader] = useState(false);
  const { clients, isError, isSuccess, message, isFetching } =
    useSelector(ClientSelector);
  const [productBilling, setProductBilling] = useState([]);
  const [serviceBilling, setServiceBilling] = useState([]);
  const [consultationBilling, setConsultationBilling] = useState([]);

  const dispatch = useDispatch();

  //gets product/service billing lists
  useEffect(() => {
    dispatch(getClients({ uid }));
    dispatch(getProductInventory({ uid }));
    dispatch(getServiceInventory({ uid }));
    dispatch(getConsultationServices({ uid }));
  }, [dispatch, uid]);

  const { products, services, consultation } = useSelector(DashboardSelector);
  useEffect(() => {
    let serviceData = [];
    let productData = [];
    let consultationData = [];
    clients.forEach((item) => {
      if (item?.services) {
        item.services.forEach((service) => {
          serviceData.push({
            name: item.name,
            clientId: item.id,
            phoneNumber: item.phoneNumber,
            email: item.email,
            customerId: item.customerId,
            ...service,
          });
        });
      }

      if (item?.consultation) {
        item.consultation.forEach((service) => {
          consultationData.push({
            name: item.name,
            clientId: item.id,
            phoneNumber: item.phoneNumber,
            email: item.email,
            customerId: item.customerId,
            ...service,
          });
        });
      }

      if (item?.products) {
        item.products.forEach((product) => {
          productData.push({
            name: item.name,
            clientId: item.id,
            phoneNumber: item.phoneNumber,
            email: item.email,
            customerId: item.customerId,
            ...product,
          });
        });
      }
    });

    productData.sort(function (a, b) {
      let createdAt1 = a.purchasedAt;
      createdAt1 = createdAt1.seconds
        ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
        : createdAt1;

      let createdAt2 = b.purchasedAt;
      createdAt2 = createdAt2.seconds
        ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
        : createdAt2;
      return new Date(createdAt2) - new Date(createdAt1);
    });

    serviceData.sort(function (a, b) {
      let createdAt1 = a.purchasedAt;
      createdAt1 = createdAt1.seconds
        ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
        : createdAt1;

      let createdAt2 = b.purchasedAt;
      createdAt2 = createdAt2.seconds
        ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
        : createdAt2;
      return new Date(createdAt2) - new Date(createdAt1);
    });

    consultationData.sort(function (a, b) {
      let createdAt1 = a.createdAt;
      createdAt1 = createdAt1.seconds
        ? createdAt1?.nanoseconds / 1000000 + createdAt1?.seconds * 1000
        : createdAt1;

      let createdAt2 = b.createdAt;
      createdAt2 = createdAt2.seconds
        ? createdAt2?.nanoseconds / 1000000 + createdAt2?.seconds * 1000
        : createdAt2;
      return new Date(createdAt2) - new Date(createdAt1);
    });

    setProductBilling(productData);
    setServiceBilling(serviceData);
    setConsultationBilling(consultationData);
  }, [clients]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(message);
      setLoader(false);
      dispatch(clearState());
    }
    if (isError) {
      toast.error(message);
      setLoader(false);
      dispatch(clearState());
    }
    if (isFetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [isSuccess, message, dispatch, isError, isFetching]);

  const downloadAndStorePdf = async (billData) => {
    try {
      await invoiceBillingPdf({ ...billData, termsAndConditions });
    } catch (err) {
      throw err;
    }
  };

  const [invoiceModal, setInvoiceModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState();
  const setInvoiceDataHandler = (clientName, name, typeId, type, clientId) => {
    setInvoiceModal(true);
    setInvoiceData({ clientName, name, type, typeId, clientId });
  };

  const [isEditable, setIsEditable] = useState(false);
  const [mode, setMode] = useState("view");
  const [viewModal, setViewModal] = useState(false); //for view modal
  const [selectedItem, setSelectedItem] = useState(null); //for selecting product for allocation
  const onViewHandler = useCallback(
    (item, type) => {
      setSelectedItem(item);
      setMode(type);
      setViewModal(true);
      setIsEditable(false);
    },
    [setMode, setViewModal, setSelectedItem]
  );
  const onEditHandler = useCallback(
    (item, type) => {
      setSelectedItem(item);
      setMode(type);
      setViewModal(true);
      setIsEditable(true);
    },
    [setSelectedItem, setViewModal, setMode]
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Tabs>
          <TabList>
            <Tab>
              <SuiBox display="flex" alignItems="center" px={1} color="auto">
                <span>Clients list (Services)</span>
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={
                      "Below is the list of clients who have taken your services"
                    }
                    placement="top"
                    style={{ color: "#67748e", height: "16px", width: "14px" }}
                  >
                    <Icon color="inherit">help</Icon>
                  </Tooltip>
                </span>
              </SuiBox>
            </Tab>
            <Tab>
              <SuiBox display="flex" alignItems="center" px={1} color="auto">
                <span>Clients list (Products)</span>
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={
                      "Below are the clients whom you have sold your diet products"
                    }
                    placement="top"
                    style={{ color: "#67748e", height: "16px", width: "14px" }}
                  >
                    <Icon color="inherit">{"help"}</Icon>
                  </Tooltip>
                </span>
              </SuiBox>
            </Tab>
            <Tab>
              <SuiBox display="flex" alignItems="center" px={1} color="auto">
                <span>Clients list (Consultations)</span>
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={"Below are the clients whom you have consulted"}
                    placement="top"
                    style={{ color: "#67748e", height: "16px", width: "14px" }}
                  >
                    <Icon color="inherit">{"help"}</Icon>
                  </Tooltip>
                </span>
              </SuiBox>
            </Tab>

            <Tab>
              <SuiBox display="flex" alignItems="center" px={1} color="auto">
                <span>Expenses</span>
                <span
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={"The clients' expenses are listed below."}
                    placement="top"
                    style={{ color: "#67748e", height: "16px", width: "14px" }}
                  >
                    <Icon color="inherit">{"help"}</Icon>
                  </Tooltip>
                </span>
              </SuiBox>
            </Tab>
          </TabList>

          <TabPanel>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ServiceTable
                    setInvoiceDataHandler={setInvoiceDataHandler}
                    downloadAndStorePdf={downloadAndStorePdf}
                    serviceBilling={serviceBilling}
                    services={services}
                    loader={loader}
                    user={user}
                    viewHandler={onViewHandler}
                    editHandler={onEditHandler}
                  />
                </Grid>
              </Grid>
            </SuiBox>
          </TabPanel>

          <TabPanel>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ProductTable
                    setInvoiceDataHandler={setInvoiceDataHandler}
                    downloadAndStorePdf={downloadAndStorePdf}
                    productBilling={productBilling}
                    products={products}
                    loader={loader}
                    user={user}
                    viewHandler={onViewHandler}
                    editHandler={onEditHandler}
                  />
                </Grid>
              </Grid>
            </SuiBox>
          </TabPanel>

          <TabPanel>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ConsultationTable
                    setInvoiceDataHandler={setInvoiceDataHandler}
                    downloadAndStorePdf={downloadAndStorePdf}
                    consultationBilling={consultationBilling}
                    services={consultation}
                    loader={loader}
                    user={user}
                    viewHandler={onViewHandler}
                    editHandler={onEditHandler}
                  />
                </Grid>
              </Grid>
            </SuiBox>
          </TabPanel>

          <TabPanel>
            <SuiBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Expense />
                </Grid>
              </Grid>
            </SuiBox>
          </TabPanel>
        </Tabs>
      </SuiBox>

      <InvoiceModal
        open={invoiceModal}
        invoiceModalData={invoiceData}
        handleCloseModal={() => {
          setTimeout(() => {
            setInvoiceData(null);
          }, 500);
          setInvoiceModal(false);
        }}
        editHandler={onEditHandler}
      />
      <ServiceModal
        open={viewModal && mode === "service"}
        mode={mode}
        isEditable={isEditable}
        formdata={selectedItem}
        handleCloseModal={() => {
          setTimeout(() => {
            setSelectedItem(null);
          }, 500);
          setViewModal(false);
        }}
        services={services}
        downloadAndStorePdf={downloadAndStorePdf}
        user={user}
      />

      <ProductModal
        open={viewModal && mode === "product"}
        mode={mode}
        isEditable={isEditable}
        item={selectedItem}
        handleCloseModal={() => {
          setTimeout(() => {
            setSelectedItem(null);
          }, 500);
          setViewModal(false);
        }}
        products={products}
        user={user}
        downloadAndStorePdf={downloadAndStorePdf}
      />

      <ConsultationModal
        open={viewModal && mode === "consultation"}
        mode={mode}
        isEditable={isEditable}
        formdata={selectedItem}
        handleCloseModal={() => {
          setTimeout(() => {
            setSelectedItem(null);
          }, 500);
          setViewModal(false);
        }}
        user={user}
        consultations={consultation}
        downloadAndStorePdf={downloadAndStorePdf}
      />
    </DashboardLayout>
  );
}

export default Billing;
